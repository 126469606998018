import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import { Link, Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from "@material-ui/core/Button";
// import {caseupdateActions} from "../../actions";
import { addSelectedCaseDetails } from "../../actions/updatecase.actions";
import { modal } from 'reactstrap';
import Modal from '@material-ui/core/Modal';
import { PropagateLoader } from 'react-spinners';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import {
  ModalHeader, ModalBody, ModalFooter,
  Popover, PopoverHeader, PopoverBody
} from 'reactstrap';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { useSelector, useDispatch } from 'react-redux';
import useCheckSessionApi from "../../SessionValidity";
import { userActions } from "../../actions";
import FilterListIcon from "@material-ui/icons/FilterList";
import FilterViewCases from "../../FilterViewCases";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import { Multiselect } from 'react-widgets';
import "react-widgets/styles.css";

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Tooltip from '@material-ui/core/Tooltip';

import TableSortLabel from '@material-ui/core/TableSortLabel';
import { utc, } from 'moment';
import moment from 'moment';


import { newCaseActionReset } from "../../actions/newcase.actions";
import {
  addqsnList, addqsnListLevelTwo, addqsnListLevelThree,
} from "../../actions/updatecase.actions";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(casesList, comparator) {
  const stabilizedThis = casesList && casesList.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // { id: 'case_id', numeric: false, label: 'Case Id', },
  { id: 'name', numeric: false, label: 'Case Name', },
  // { id: 'description', numeric: false, label: 'Case Description', },
  { id: 'service', numeric: false, label: 'Service', },
  { id: 'created_date', numeric: false, label: 'Created Time (Local)', },
  // { id: 'process', numeric: false, label: 'Process', },
  // { id: 'service', numeric: false, label: 'Service', },
  // { id: 'description', numeric: false, label: 'Description', },
  { id: 'client_name', numeric: false, label: 'Client Name', },
  { id: 'client_pan', numeric: false, label: 'Client PAN', },
  { id: 'for_assesse_name', numeric: false, label: 'Tax Payer Name', },
  // { id: 'owner', numeric: false, label: 'Owner', },

  // { id: 'for_assesse_pan', numeric: false, label: 'ASSESSE PAN', },
  { id: 'case_curr_state', numeric: false, label: 'Case Status', },
  { id: 'priority', numeric: false, label: 'Priority', },
  { id: 'current_step', numeric: false, label: 'Current Step', },
  { id: 'current_state', numeric: false, label: 'Current Step State', },
  { id: 'actions', numeric: false, label: 'Actions', },
  // { id: 'actions', numeric: false, label: 'Settings', },
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          (headCell.id === "actions") ?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{ fontWeight: 'bold', fontSize: 17, width: "5%" }}
              >
                {headCell.label}
              </TableCell>
            )
            : (
              <TableCell
                key={headCell.id}
                // align={headCell.numeric ? 'center' : 'center'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                align="center"
                style={{ fontWeight: 'bold', fontSize: 17, width: "10%" }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )


        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  rowHeight: {
    height: 2,
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  rootrp: {
    flexGrow: 1,
    // backgroundColor: 'white',
  },
  cursorPointer: {
    cursor: "pointer"
  },
  wrapper: {
    // margin: "10%",
    // marginRight: "10%",
    // marginTop: "1%",
    // marginBottom: "3%",
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    boxShadow: 'none',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: '0px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // overflow:'scroll',
  },
  paperModalAssigncaseOwner: {
    position: 'absolute',
    width: 500,
    height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'6%',
    display: 'block',
    overflowY: 'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'4%',
    display: 'block',
    overflowY: 'scroll',
  },
  paperModalSwitchStep: {
    position: 'absolute',
    width: 500,
    height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'4%',
    display: 'block',
    overflowY: 'scroll',
  },
  paperModalPendingInfo: {
    position: 'absolute',
    width: 500,
    height: 300,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'4%',
    display: 'block',
    overflowY: 'scroll',
  },
  paperModalFilter: {
    position: 'absolute',
    width: 600,
    height: 590,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'5%',
    display: 'block',
    overflowY: 'scroll',
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height: '14%',
  },
  SwitchStepHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height: '14%',
  },
  PendingInfoHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height: '25%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),
    width: '100%',
  },
  modalBodyFilters: {
    padding: theme.spacing(1, 4, 3),
    width: '100%',
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  buttonSwalDelete: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
    cursor: 'pointer',
  },
  buttonSwalCancel: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
    marginRight: "2%",
    cursor: 'pointer',
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },

  customFilterLabel: {
    textTransform: "none",
    background: "#FFFFFF",
    margin: 1,
    // marginTop:'-5%',
    // marginLeft:'60%',
  },
  buttonStyle: {
    margin: 1,
    marginLeft: '5%',
  },
  buttonStyleNewCase: {
    margin: 1,
    marginLeft: '1%',
  },
  filterLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "21px",
    color: "rgba(0, 0, 0, 0.96)",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitApplyFilters: {
    margin: theme.spacing(3, 0, 2),
    // width: '3%',
  },
  subHeadingLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: "black",
    marginBottom: "4px",
  },
  subHeadingLabeltwo: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: "black",
    marginTop: "20px",
    marginBottom: "4px",
  },
  subHeadingLabelSelectUser: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: "black",
    // marginBottom: "10px",
  },
  textTransformNone: {
    textTransform: "none",
    color: 'black'
  },
  marginRight: {
    marginRight: "4%",
  },
  tagButtonStyle: {
    textTransform: "none",
    // background: "#FDD600",
  },
  hover: {
    cursor: "pointer",
  },
}));

const ITEM_HEIGHT = 48;

export default function TaxManagementCasesTableTrpAdmin() {
  const classes = useStyles();
  const [flag, setflag] = React.useState(false);
  const [ShowProcedure, setShowProcedure] = React.useState(false);
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));
  const [casesList, setcasesList] = React.useState([]);
  const [paginationDetails, setpaginationDetails] = React.useState({total_cases:0});

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);

  const anchorRef = React.useRef(null);
  const [OpenChangeStatus, setOpenChangeStatus] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [anchorElTwo, setAnchorElTwo] = React.useState(null);
  const openConfirmed = Boolean(anchorElTwo);

  const [anchorElThree, setAnchorElThree] = React.useState(null);
  const openPending = Boolean(anchorElThree);

  const [anchorElFour, setanchorElFour] = React.useState(null);
  const openCompleted = Boolean(anchorElFour);

  const [anchorInitiated, setAnchorInitiated] = React.useState(null);
  const openInitiated = Boolean(anchorInitiated);

  const [anchorInitiated2, setAnchorInitiated2] = React.useState(null);
  const openInitiated2 = Boolean(anchorInitiated2);

  const [RedirectToTaxManagementCaseDetails, setRedirectToTaxManagementCaseDetails] = React.useState(false);
  const [RedirectToTrpEditCase, setRedirectToTrpEditCase] = React.useState(false);
  const [RedirectToGetClientDetailsPage, setRedirectToGetClientDetailsPage] = React.useState(false);
  const [RedirectToEditClientDetails, setRedirectToEditClientDetails] = React.useState(false);
  const [RedirectToClientEditCase, setRedirectToClientEditCase] = React.useState(false);

  const [slectedCaseForDetailsHere, setslectedCaseForDetailsHere] = React.useState([]);
  const [processDetailsByService, setprocessDetailsByService] = React.useState([]);
  const [openSwitchProcess, setopenSwitchProcess] = React.useState(false);
  const [selectedProcessToSwitch, setselectedProcessToSwitch] = React.useState();
  const [selectedProcessNameToSwitch, setselectedProcessNameToSwitch] = React.useState();
  const [selectedProcessIdToSwitch, setselectedProcessIdToSwitch] = React.useState();
  const [switchProcessErr, setswitchProcessErr] = React.useState();

  // AssignUsers
  const [openAssignUser, setopenAssignUser] = React.useState(false);
  const [assignUserDetails, setassignUserDetails] = React.useState([]);
  const [assignNewUserDetails, setassignNewUserDetails] = React.useState({
    case_id: "",
    owner_id: "",
    assigned_by_id: "",
    comments: "",
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });
  const [selectedUserToAssignId, setselectedUserToAssignId] = React.useState();
  const [selectedUserToAssignName, setselectedUserToAssignName] = React.useState("");
  // const [commentErr, setcommentErr] = React.useState("");
  const [assignUserErr, setassignUserErr] = React.useState("");

  const [openSwitchStep, setopenSwitchStep] = React.useState(false);
  const [caseDetailsToSwitchStep, setcaseDetailsToSwitchStep] = React.useState([]);
  const [selectedStepNameToSwith, setselectedStepNameToSwith] = React.useState("");
  const [SelectedStepNameIdIs, setSelectedStepNameIdIs] = React.useState();
  const [switchStepErr, setswitchStepErr] = React.useState();
  const [switchRadio, setswitchRadio] = React.useState(true);

  // Filters
  const [OpenFilter, setOpenFilter] = React.useState(false);
  const [allTagDetails, setallTagDetails] = React.useState([]);
  const [allUsersList, setallUsersList] = React.useState([]);
  const [AssignUserFilter, setAssignUserFilter] = React.useState([]);
  const [AssignUserFilterNames, setAssignUserFilterNames] = React.useState([]);
  const [TagFilter, setTagFilter] = React.useState([]);
  const [TagFilterNames, setTagFilterNames] = React.useState([]);
  const [MultiselectRefresh, setMultiselectRefresh] = React.useState(true);
  const [FilterIsApplied, setFilterIsApplied] = React.useState(false);
  const [allServicesDetails, setallServicesDetails] = React.useState([]);
  const [ServiceFilter, setServiceFilter] = React.useState([]);
  const [ServiceFilterNames, setServiceFilterNames] = React.useState([]);
  const [allRolesDetails, setallRolesDetails] = React.useState([]);
  const [RoleFilter, setRoleFilter] = React.useState([]);
  const [RoleFilterNames, setRoleFilterNames] = React.useState([]);
  const [allClientGroupDetails, setallClientGroupDetails] = React.useState([]);
  const [ClientGroupsFilter, setClientGroupsFilter] = React.useState([]);
  const [ClientGroupsFilterNames, setClientGroupsFilterNames] = React.useState([]);
  const [ClientsFilter, setClientsFilter] = React.useState([]);
  const [ClientsFilterNames, setClientsFilterNames] = React.useState([]);

  const [ShowCasesForActiveClients, setShowCasesForActiveClients] = React.useState(false);

  // sort 
  const [SortByArray, setSortByArray] = React.useState([
    { name: 'Recent', value: 'recent', selected: false },
    { name: 'Priority', value: 'priority', selected: false },
    { name: 'Status', value: 'status', selected: false },
  ]);
  // priority
  const [PriorityArray, setPriorityArray] = React.useState([
    // {name: 'Any', value: 'any', selected: false},
    { name: 'Low', value: 'low', selected: false },
    { name: 'Medium', value: 'medium', selected: false },
    { name: 'High', value: 'high', selected: false },
  ]);

  // reject case
  const [OpenRejectCase, setOpenRejectCase] = React.useState(false);
  const [RejectCaseComment, setRejectCaseComment] = React.useState("");
  const [RejectCaseCommentErr, setRejectCaseCommentErr] = React.useState("");

  // pending info
  const [OpenPendingInfo, setOpenPendingInfo] = React.useState(false);
  const [PendingInfoComment, setPendingInfoComment] = React.useState("");
  const [pendingInfoCommentErr, setpendingInfoCommentErr] = React.useState("");

  // Close Case
  const [OpenCloseCase, setOpenCloseCase] = React.useState(false);
  const [CloseCaseComment, setCloseCaseComment] = React.useState("");
  const [CloseCaseCommentErr, setCloseCaseCommentErr] = React.useState("");

  const [ClientDetails, setClientDetails] = React.useState([]);

  let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
  // console.log("user_permissions from local storage on header page::::::",user_permissions)


  // Redux set
  const dispatch = useDispatch();
  const addSelectedCaseDetailsRedux = (selectedCaseDetails) => dispatch(addSelectedCaseDetails(selectedCaseDetails));

  const addqsnListRedux = () => dispatch(addqsnList());
  const addqsnListLevelTwoRedux = (qsnListLevelTwo) => dispatch(addqsnListLevelTwo(qsnListLevelTwo));
  const addqsnListLevelThreeRedux = (qsnListLevelThree) => dispatch(addqsnListLevelThree(qsnListLevelThree));
  const newCaseActionResetCallRedux = () => dispatch(newCaseActionReset());

  // const [page, setPage] = React.useState();
  const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const [totalNumberOfPagesFromApi, settotalNumberOfPagesFromApi] = React.useState();
  const [recentPageNumberFromApi, setrecentPageNumberFromApi] = React.useState();
  const [ShowTableFlag, setShowTableFlag] = React.useState(true);

  const [FlagTwo, setFlagTwo] = React.useState(false);


  const prevOpen = React.useRef(OpenChangeStatus);
  useEffect(() => {
    // readItemFromStorage();

    // console.log("page newPage on case table",page)
    // console.log("page newPage on case table from localstorage", localStorage.getItem('pageNumberOfTableCasesTableTrpAdmin'))
    // let PageFromLocalStorageIs = localStorage.getItem('pageNumberOfTableCasesTableTrpAdmin')
    // setPage(PageFromLocalStorageIs)

    // console.log("rows per page on case table from localstorage", localStorage.getItem('rowsPerPageOfTableCasesTableTrpAdmin'))
    // let RowsPerPageFromLocalStorageIs = localStorage.getItem('rowsPerPageOfTableCasesTableTrpAdmin')
    // setRowsPerPage(RowsPerPageFromLocalStorageIs)
    // console.log("page newPage on case table after local storage use",page)

    dispatch(userActions.checkSessionValidity());
    setflag(true);
    if (prevOpen.current === true && OpenChangeStatus === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = OpenChangeStatus;


    let viewClientCases = JSON.parse(localStorage.getItem("view_clients_active_cases"))
    // console.log("viewClientCases in cases table", viewClientCases)
    // if( viewClientCases === null ){
    //   console.log("yes it's null")
    // }
    // else if( viewClientCases !== null ){
    //   console.log("no it's not null")
    // }

    if (viewClientCases) {
      setShowCasesForActiveClients(true)
      setShowProcedure(true);
      let per_page_here = 100;
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          'client_id': viewClientCases.client_id,
          'page_number': 1, 'page_size': per_page_here,
          'device_token': device_token, 'session_id': session_id
        })
      };
      // REACT_APP_GET_CLIENT_CASES
      let url = process.env.REACT_APP_GET_CLIENT_CASES
      // console.log("casesDetails requestOptions,url)))))))))))))))))))))))))+++++++++++++++++",requestOptions,url,);
      fetch(process.env.REACT_APP_GET_CLIENT_CASES, requestOptions)
        .then((Response) => Response.json())
        .then(casesDetails => {
          setShowProcedure(false);
          console.log("casesDetails on same page", casesDetails);
          // console.log("casesDetails pagination filter",casesDetails.data.total_cases);

          if (casesDetails.data.cases) {
            setcasesList(casesDetails.data.cases)
          }
          if (casesDetails.data.pagination) {
            let PaginationData = casesDetails.data.pagination

            setpaginationDetails(casesDetails.data.pagination)

            // setRowsPerPage(casesDetails.data.cases.length)
            setRowsPerPage(per_page_here)

            setrecentPageNumberFromApi(casesDetails.data.pagination.current_page)

            settotalNumberOfPagesFromApi(casesDetails.data.pagination.total_pages)
            
            // console.log("PaginationData is",PaginationData)

            // settotalNumberOfPagesFromApi(casesDetails.data.pagination.total_pages)
            // if(casesDetails.data.pagination.total_cases <= 100 ){
            //   setRowsPerPage(casesDetails.data.pagination.total_cases - 1)
            //   // setRowsPerPage(5)
            // }
            // else{
            //   setRowsPerPage(100)
            // }
          }
          // else{
          //   setpaginationDetails({...paginationDetails, total_cases:total_cases_here })
          // }
          if (casesDetails.success == false) {
            setcasesList([])
            let total_cases_here = 0
            setpaginationDetails({...paginationDetails, total_cases:total_cases_here })
            Swal.fire({
              icon: 'error',
              text: casesDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
    else {
      setShowCasesForActiveClients(false)
      setShowProcedure(true);
      let per_page_here = 100;
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          'device_token': device_token, 'session_id': session_id,
          'page_number': 1, 'page_size': per_page_here
        })
      };
      // let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_GET_CASES_URL
      let url = process.env.REACT_APP_GET_CASES_URL
      console.log("casesDetails requestOptions,url)))))))))))))))))))))))))+++++++++++++++++", requestOptions, url,);
      fetch(process.env.REACT_APP_GET_CASES_URL, requestOptions)
        .then((Response) => Response.json())
        .then(casesDetails => {
          setShowProcedure(false);
          // console.log("casesDetails on same page",casesDetails);

          if (casesDetails.data.cases) {
            setcasesList(casesDetails.data.cases)
          }
          if (casesDetails.data.pagination) {
            setpaginationDetails(casesDetails.data.pagination)

            // setRowsPerPage(casesDetails.data.cases.length)
            setRowsPerPage(per_page_here)

            setrecentPageNumberFromApi(casesDetails.data.pagination.current_page)

            settotalNumberOfPagesFromApi(casesDetails.data.pagination.total_pages)
            // if(casesDetails.data.pagination.total_cases <= 100 ){
            //   setRowsPerPage(casesDetails.data.pagination.total_cases - 1)
            //   // setRowsPerPage(5)
            // }
            // else{
            //   setRowsPerPage(100)
            // }

            // let PaginationData = casesDetails.data.pagination
            // console.log("PaginationData is",PaginationData)
            // if( casesDetails.data.pagination.total_pages == 1){
            //   setRowsPerPage(casesDetails.data.pagination.total_cases)
            // } 
          }
          if (casesDetails.success == false) {
            setcasesList([])
            let total_cases_here = 0
            setpaginationDetails({...paginationDetails, total_cases:total_cases_here })
            Swal.fire({
              icon: 'error',
              text: casesDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }

    const requestOptionsAllTags = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id,
        'device_token': device_token, 'session_id': session_id
      })
    };
    fetch(process.env.REACT_APP_GET_BUSINESS_TAGS, requestOptionsAllTags)
      .then((Response) => Response.json())
      .then(async (AllTagDetails) => {
        // console.log("AllTagDetails on show case table",AllTagDetails);
        if (AllTagDetails.success == true) {
          // console.log("AllTagDetails",AllTagDetails)
          await setallTagDetails(AllTagDetails.data.business_tags_info)
        }
        else if (AllTagDetails.success == false) {
          setallTagDetails([]);
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    const requestOptionsUsers = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id,
        'device_token': device_token, 'session_id': session_id
      })
    };
    fetch(process.env.REACT_APP_GET_BUSINESS_TEAM, requestOptionsUsers)
      .then((Response) => Response.json())
      .then(async (allUsersDetails) => {
        // setShowProcedure(false);
        // console.log("allUsersDetails",allUsersDetails);
        if (allUsersDetails.success == true) {
          setallUsersList(allUsersDetails.data.business_team);
        }
        else if (allUsersDetails.success == false) {
          setallUsersList([]);
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    // to get all clients
    const requestOptionsClients = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id, 'device_token': device_token,
        'session_id': session_id
      })
    };

    fetch(process.env.REACT_APP_GET_CLIENTS, requestOptionsClients)
      .then((Response) => Response.json())
      .then(clientDetails => {
        console.log("clientDetails on same page",clientDetails.data.clients);
        if (clientDetails.data.clients) {
          // setClientDetails(clientDetails.data.clients)
          setClientDetails(
            clientDetails.data.clients.map((f) => {
              console.log("f is",f)
              if (f.client_type === 'person' ) 
              {
                f.fullNameOrOrg = f.first_name + ' ' + f.middle_name + ' ' + f.surname;
              }
              else if( f.client_type === 'business' )
              {
                f.fullNameOrOrg = f.org_name
              }
              return f;
            })
          );
        }
        if (clientDetails.success == false) {
          setClientDetails([])
          Swal.fire({
            icon: 'error',
            text: clientDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    // To get all services
    const requestOptionsServices = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id, 'device_token': device_token,
        'session_id': session_id, 'category_type':"service"
      })
    };
    fetch(process.env.REACT_APP_GET_CATEGORIES, requestOptionsServices)
      .then((Response) => Response.json())
      .then(allServiceDetails => {
        console.log("allServiceDetails on same page",allServiceDetails.data.categories);
        if (allServiceDetails.data.categories) {
          setallServicesDetails(allServiceDetails.data.categories)
        }
        if (allServiceDetails.success == false) {
          setallServicesDetails([])
          Swal.fire({
            icon: 'error',
            text: allServiceDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    // To get all Roles
    const requestOptionsRoles = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id, 'device_token': device_token,
        'session_id': session_id
      })
    };
    fetch(process.env.REACT_APP_GET_BUSINESS_ROLES, requestOptionsRoles)
      .then((Response) => Response.json())
      .then(allRoleDetails => {
        console.log("allRoleDetails on same page",allRoleDetails.data.roles);
        if (allRoleDetails.data.roles) {
          setallRolesDetails(allRoleDetails.data.roles)
        }
        if (allRoleDetails.success == false) {
          setallRolesDetails([])
          Swal.fire({
            icon: 'error',
            text: allRoleDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    // To get all Client Groups
    const requestOptionsClientGroups = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id, 'device_token': device_token,
        'session_id': session_id
      })
    };
    fetch(process.env.REACT_APP_GET_BUSINESS_CLIENT_GROUPS, requestOptionsClientGroups)
      .then((Response) => Response.json())
      .then(allClientGroupsDetails => {
        console.log("allClientGroupsDetails on same page",allClientGroupsDetails.data.client_groups);
        if (allClientGroupsDetails.data.client_groups) {
          setallClientGroupDetails(allClientGroupsDetails.data.client_groups)
        }
        if (allClientGroupsDetails.success == false) {
          setallClientGroupDetails([])
          Swal.fire({
            icon: 'error',
            text: allClientGroupsDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

  }, [flag]);

  useEffect(() => {
    // setFlagTwo(true);

      console.log('retrieving casesList', casesList);

  }, [FlagTwo, casesList]);




  // sort
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows =
    // rowsPerPage - Math.min(rowsPerPage, casesList && casesList.length - page * rowsPerPage);
    rowsPerPage - Math.min(rowsPerPage, paginationDetails && paginationDetails.total_cases - page * rowsPerPage);

  const handleChangePage = async (event, newPage) => {
    console.log("in handleChangePage set page", newPage)
    console.log("in change page check if filter is applied or not",FilterIsApplied)
    let nuberOfRowsPerPage = rowsPerPage

    // console.log("in handle change page event",event.page)
    setPage(newPage);
    // await localStorage.setItem('pageNumberOfTableCasesTableTrpAdmin', newPage);

    // to get next / back page's cases

    if( FilterIsApplied === true ){
      getNextFilteredCases(newPage, nuberOfRowsPerPage)
    }
    else if( ShowCasesForActiveClients === true ){
      getNextClientActiveCases( newPage, nuberOfRowsPerPage )
    }
    else{
      getNextCasesAfterPagination(newPage, nuberOfRowsPerPage)
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    console.log("in handleChangeRowsPerPage set page", event.target.value, parseInt(event.target.value, 10))
    console.log("in change page check if filter is applied or not",FilterIsApplied)
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    
    // await localStorage.setItem('rowsPerPageOfTableCasesTableTrpAdmin', parseInt(event.target.value, 10));
    // await localStorage.setItem('pageNumberOfTableCasesTableTrpAdmin', 0);

    let Rows_per_page_here = parseInt(event.target.value, 10);
    let page_here = 0;

    if( FilterIsApplied === true ){
      getNextFilteredCases(page_here, Rows_per_page_here)
    }
    else if( ShowCasesForActiveClients === true ){
      getNextClientActiveCases( page_here, Rows_per_page_here )
    }
    else{
      getNextCasesAfterPagination(page_here, Rows_per_page_here)
    }

    // getNextCasesAfterPagination(page_here, Rows_per_page_here)

  };

  const getNextCasesAfterPagination = (newPage, nuberOfRowsPerPage) => {
    console.log("new page number is", newPage, newPage + 1);
    console.log("nuberOfRowsPerPage in next", nuberOfRowsPerPage);

    setShowProcedure(true);
    setShowTableFlag(false);
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'device_token': device_token, 'session_id': session_id,
        'page_number': newPage + 1, 'page_size': nuberOfRowsPerPage
      })
    };
    let url = process.env.REACT_APP_GET_CASES_URL
    console.log("casesDetails requestOptions,url)))))))))))))))))))))))))+++++++++++++++++", requestOptions, url,);
    fetch(process.env.REACT_APP_GET_CASES_URL, requestOptions)
      .then((Response) => Response.json())
      .then(casesDetails => {
        setShowProcedure(false);

        console.log("casesDetails after pagination", casesDetails);
        if (casesDetails.data.cases) {
          setcasesList(casesDetails.data.cases)
          setShowTableFlag(true)
        }
        if (casesDetails.data.pagination) {
          setpaginationDetails(casesDetails.data.pagination)

          // setRowsPerPage(casesDetails.data.cases.length)
          setRowsPerPage(nuberOfRowsPerPage)

          setrecentPageNumberFromApi(casesDetails.data.pagination.current_page)

          settotalNumberOfPagesFromApi(casesDetails.data.pagination.total_pages)


        }
        if (casesDetails.success == false) {
          setcasesList([])
          let total_cases_here = 0
          setpaginationDetails({...paginationDetails, total_cases:total_cases_here })
          Swal.fire({
            icon: 'error',
            text: casesDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

  }

  const getNextFilteredCases = (newPage, nuberOfRowsPerPage) => {
    console.log("new page number is filter cases", newPage, newPage + 1);
    console.log("nuberOfRowsPerPage in next filter cases", nuberOfRowsPerPage);

    let SortBySelectedHere = "";
    SortByArray.map((f) => {
      if (f.selected == true) {
        // console.log("true f is",f)
        SortBySelectedHere = f.value;
      }
    })
    // console.log("SortBySelectedHere",SortBySelectedHere);
    //priority
    let PrioritySelectedHere = "";
    PriorityArray.map((f) => {
      if (f.selected == true) {
        // console.log("true f is",f)
        PrioritySelectedHere = f.value;
      }
    })
    // console.log("PrioritySelectedHere",PrioritySelectedHere);
    // tag
    const tagsForFilter = [];
    allTagDetails.map((f) => {
      if (f.selected == true) {
        // console.log("true f is",f)
        tagsForFilter.push(f.tag_id);
      }
    })
    // console.log("tagsForFilter is",tagsForFilter)
    // assigned people allUsersList
    const assignedPeopleForFilter = [];
    allUsersList.map((f) => {
      if (f.selected == true) {
        // console.log("true f is",f)
        assignedPeopleForFilter.push(f.user_id);
      }
    })

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'search_str': '', 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id,
        'sort_by': SortBySelectedHere, 'priority': PrioritySelectedHere, 'tags': TagFilter,
        'services':ServiceFilter, 'clients':ClientsFilter,
        // 'roles':RoleFilter, 'client_groups': ClientGroupsFilter,
        'assigned_users': AssignUserFilter, "client_type": "ALL",
        'page_number': newPage + 1, 'page_size': nuberOfRowsPerPage
      })
    };

    fetch(process.env.REACT_APP_SEARCH_CASES, requestOptions)
      .then((Response) => Response.json())
      .then(async (SearchDetailsFilter) => {
        setShowProcedure(false);
        console.log("SearchDetailsFilter is==",SearchDetailsFilter.data.pagination);
        if (SearchDetailsFilter.data.search_results) {
 
          setcasesList(SearchDetailsFilter.data.search_results.cases);

          // setpaginationDetails(SearchDetailsFilter.data.pagination)

        }
        if (SearchDetailsFilter.data.pagination) {
          setpaginationDetails(SearchDetailsFilter.data.pagination)

          // setRowsPerPage(casesDetails.data.cases.length)
          setRowsPerPage(nuberOfRowsPerPage)

          setrecentPageNumberFromApi(SearchDetailsFilter.data.pagination.current_page)

          settotalNumberOfPagesFromApi(SearchDetailsFilter.data.pagination.total_pages)


        }
        if (SearchDetailsFilter.success == false) {
          setcasesList([])
          let total_cases_here = 0
          setpaginationDetails({...paginationDetails, total_cases:total_cases_here })
          Swal.fire({
            icon: 'error',
            // text: SearchDetailsFilter.errors,
            // text: "Something went wrong, retry with different filters",
            text: "No results found for search, please update search criteria and try again",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

  }

  const getNextClientActiveCases = (newPage, nuberOfRowsPerPage) => {
    console.log("new page number is client active cases", newPage, newPage + 1);
    console.log("nuberOfRowsPerPage in next client active cases", nuberOfRowsPerPage);

    let viewClientCases = JSON.parse(localStorage.getItem("view_clients_active_cases"))

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'client_id': viewClientCases.client_id,
        'device_token': device_token, 'session_id': session_id,
        'page_number': newPage + 1, 'page_size': nuberOfRowsPerPage
      })
    };
    fetch(process.env.REACT_APP_GET_CLIENT_CASES, requestOptions)
      .then((Response) => Response.json())
      .then(casesDetails => {
        setShowProcedure(false);
        console.log("casesDetails on same page", casesDetails);
        if (casesDetails.data.cases) {
          setcasesList(casesDetails.data.cases)
        }
        if (casesDetails.data.pagination) {
          let PaginationData = casesDetails.data.pagination

          setpaginationDetails(casesDetails.data.pagination)

          setRowsPerPage(nuberOfRowsPerPage)

          setrecentPageNumberFromApi(casesDetails.data.pagination.current_page)

          settotalNumberOfPagesFromApi(casesDetails.data.pagination.total_pages)
          
        }
        if (casesDetails.success == false) {
          setcasesList([])
          let total_cases_here = 0
          setpaginationDetails({...paginationDetails, total_cases:total_cases_here })
          Swal.fire({
            icon: 'error',
            text: casesDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }




  // const readItemFromStorage = async() => {
  //   try {
  //     setDeviceToken(deviceTokenFromRedux)
  //     setSessionId(sessionIdFromRedux)
  //     setflag(false)    
  //   } catch (error) {
  //     console.log("ERROR:",error);        }
  // };

  // Competed case overflow
  const handleClickShowCaseDetailsCompleted = async (event, item) => {
    // console.log("show case completed clicked is",item);
    setanchorElFour(event.currentTarget);
    await localStorage.setItem('selected_case_for_details', JSON.stringify(item));
    await localStorage.setItem('navigated_toshow_case_from', JSON.stringify("Cases"));
    setslectedCaseForDetailsHere(item)
  }

  const handleCloseCaseCompleted = async (Settings) => {
    setanchorElFour(null)

    if (Settings == "CloseCase") {
      // console.log("close case clicked")
      setOpenCloseCase(true);
    }

  }

  // sHOW DETAILS pENDING
  const handleClickShowCaseDetailspending = async (event, item) => {
    // console.log("show case pending clicked is",item);
    setAnchorElThree(event.currentTarget);
    await localStorage.setItem('selected_case_for_details', JSON.stringify(item));
    await localStorage.setItem('navigated_toshow_case_from', JSON.stringify("Cases"));
    setslectedCaseForDetailsHere(item)
  }

  const handleCloseShowCasePending = async (Settings) => {
    setAnchorElThree(null);
    setselectedStepNameToSwith("")

    if (Settings == "SwitchStep") {
      // console.log("switch step clicked")
      // console.log("selected case is", JSON.parse(localStorage.getItem('selected_case_for_details')));
      let selectedCaseIs = JSON.parse(localStorage.getItem('selected_case_for_details'));
      let selectedCaseId = selectedCaseIs.case_id;
      // console.log("selectedCaseId",selectedCaseId);

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          'case_id': selectedCaseId,
          'device_token': device_token, 'session_id': session_id
        })
      };
      fetch(process.env.REACT_APP_GET_CASE_STEPS, requestOptions)
        .then((Response) => Response.json())
        .then(async (caseDetailsToSwitchSteps) => {
          // console.log("caseDetailsToSwitchStep response",caseDetailsToSwitchSteps);
          if (caseDetailsToSwitchSteps.success == true) {
            if (caseDetailsToSwitchSteps.data.case_step_info) {
              // setcaseDetailsToSwitchStep(caseDetailsToSwitchStep.data.case_step_info);
              var joined = [];
              caseDetailsToSwitchSteps.data.case_step_info.map((data) => {
                if (data.is_completed === true || data.is_current == true) {
                  // console.log("data is",data)
                  joined = joined.concat(data);
                  // console.log("data join : ",joined);
                  setcaseDetailsToSwitchStep(joined)
                }
              })

              caseDetailsToSwitchSteps.data.case_step_info.map((f) => {
                if (f.is_current == true) {
                  // console.log("this is is current step",f.step_info.name)
                  setselectedStepNameToSwith(f.step_info.name)
                  setSelectedStepNameIdIs(f.step_info.step_id)
                  setflag(true);
                }
              })
            }
            else {
              setcaseDetailsToSwitchStep([]);
            }
            handleSwitchStepModalOpen();
          }
          else if (caseDetailsToSwitchStep.success == false) {
            setcaseDetailsToSwitchStep([]);
            Swal.fire({
              icon: 'error',
              text: "No Steps To Switch Yet",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

    }

    if (Settings == "AssignUser") {
      // console.log("AssignUser in progress");
      // console.log("AssignUser selected case is", JSON.parse(localStorage.getItem('selected_case_for_details')));
      let selectedCaseIs = JSON.parse(localStorage.getItem('selected_case_for_details'));
      let selectedCasecase_id = selectedCaseIs.case_id;

      let LogedInUser = JSON.parse(localStorage.getItem('users'));
      let LogedInUserId = LogedInUser.data.user_details.user_id
      // console.log("LogedInUserId,LogedInUser",LogedInUserId,LogedInUser);

      // console.log("selectedCasecase_id",selectedCasecase_id);
      // setassignNewUserDetails({ ...assignNewUserDetails,
      //   case_id: selectedCasecase_id,
      //   assigned_by_id: LogedInUserId,
      // })

      // to get case assigned user 
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          'case_id': selectedCasecase_id,
          'device_token': device_token, 'session_id': session_id
        })
      };
      fetch(process.env.REACT_APP_GET_CASE_ASSIGNMENT, requestOptions)
        .then((Response) => Response.json())
        .then(async (assignUserDetails) => {
          // console.log("assignUserDetails====",assignUserDetails);
          if (assignUserDetails.success == true) {
            if (assignUserDetails.data.case_assignment) {
              // console.log("assignUserDetails in if",assignUserDetails.data.case_assignment);

              await setassignUserDetails(assignUserDetails.data);
              await setselectedUserToAssignId(assignUserDetails.data.case_assignment.owner_id);
              await setselectedUserToAssignName(assignUserDetails.data.case_assignment.owner);
              setassignNewUserDetails({
                ...assignNewUserDetails,
                case_id: selectedCasecase_id,
                assigned_by_id: LogedInUserId,
                owner_id: assignUserDetails.data.case_assignment.owner_id,
                comments: assignUserDetails.data.case_assignment.comments,
              })
              // setassignNewUserDetails({ ...assignNewUserDetails,
              //   comments: assignUserDetails.data.case_assignment.comments,
              // })
              setswitchRadio(true)

              await setallUsersList(
                allUsersList.map((f) => {
                  if (f.user_id == assignUserDetails.data.case_assignment.owner_id) {
                    console.log("user and owner id matched", f.user_id, assignUserDetails.data.case_assignment.owner_id)
                    f.userSelected = true
                  }
                  else {
                    console.log("user and owner id not matched", f.user_id, assignUserDetails.data.case_assignment.owner_id)
                    f.userSelected = false
                  }
                  return f;
                })
              )

            }
            else {
              setassignUserDetails([]);
              setselectedUserToAssignId();
              setselectedUserToAssignName("");
              // setswitchRadio(true)
            }
          }
          else if (assignUserDetails.success == false) {
            setassignUserDetails([]);
            setselectedUserToAssignId();
            setselectedUserToAssignName("");
            // setswitchRadio(true)
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      handleAssignUserModalOpen();
    }

    if (Settings == "PendingInfoAway") {
      console.log("away from Pending info clicked PendingInfoAway")
      // setOpenPendingInfo(true);
      let selectedCaseIs = JSON.parse(localStorage.getItem('selected_case_for_details'));
      let selectedCaseId = selectedCaseIs.case_id;
      console.log("selectedCaseId", selectedCaseId);
      let awayFromPendingCaseObj = ({
        case_id: selectedCaseId,
        case_status: 'away-from-pending',
        device_token: device_token,
        session_id: session_id,
      })
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: classes.buttonSwalDelete,
          cancelButton: classes.buttonSwalCancel,
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, confirm it',
        cancelButtonText: 'No, cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          setShowProcedure(true);
          const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(awayFromPendingCaseObj)
          };
          fetch(process.env.REACT_APP_UPDATE_CASE_STATUS, requestOptions)
            .then((Response) => Response.json())
            .then(casesDetailsUpdated => {
              setShowProcedure(false);
              console.log("casesDetailsUpdated on same page", casesDetailsUpdated);
              if (casesDetailsUpdated.success == true) {
                Swal.fire({
                  icon: 'success',
                  text: "Case Status Updated Succesfully!!",
                  confirmButtonColor: 'primary',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
                setflag(false);
              }
              if (casesDetailsUpdated.success == false) {
                Swal.fire({
                  icon: 'error',
                  text: casesDetailsUpdated.errors,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
              }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });

        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          // swalWithBootstrapButtons.fire(
          //   'Cancelled',
          //   'Your imaginary file is safe :)',
          //   'error'
          // )
        }
      })

    }

    if (Settings == "CloseCase") {
      // console.log("close case clicked")
      setOpenCloseCase(true);
    }
  }

  // SHOW DETAILS CONFIRMED
  const handleClickShowCaseDetailsCONFIRMED = async (event, item) => {
    // console.log("show case  clicked is",item);
    setAnchorElTwo(event.currentTarget);
    await localStorage.setItem('selected_case_for_details', JSON.stringify(item));
    await localStorage.setItem('navigated_toshow_case_from', JSON.stringify("Cases"));
    setslectedCaseForDetailsHere(item)
  }

  const handleCloseShowCaseCONFIRMED = async (Settings) => {
    setAnchorElTwo(null);
    setselectedStepNameToSwith("")

    if (Settings == "SwitchStep") {
      // console.log("switch step clicked")
      // console.log("selected case is", JSON.parse(localStorage.getItem('selected_case_for_details')));
      let selectedCaseIs = JSON.parse(localStorage.getItem('selected_case_for_details'));
      let selectedCaseId = selectedCaseIs.case_id;
      // console.log("selectedCaseId",selectedCaseId);

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          'case_id': selectedCaseId,
          'device_token': device_token, 'session_id': session_id
        })
      };
      fetch(process.env.REACT_APP_GET_CASE_STEPS, requestOptions)
        .then((Response) => Response.json())
        .then(async (caseDetailsToSwitchSteps) => {
          // console.log("caseDetailsToSwitchStep response",caseDetailsToSwitchSteps);
          if (caseDetailsToSwitchSteps.success == true) {
            if (caseDetailsToSwitchSteps.data.case_step_info) {
              // setcaseDetailsToSwitchStep(caseDetailsToSwitchStep.data.case_step_info);
              var joined = [];
              caseDetailsToSwitchSteps.data.case_step_info.map((data) => {
                if (data.is_completed === true || data.is_current == true) {
                  // console.log("data is",data)
                  joined = joined.concat(data);
                  // console.log("data join : ",joined);
                  setcaseDetailsToSwitchStep(joined)
                }
              })

              caseDetailsToSwitchSteps.data.case_step_info.map((f) => {
                if (f.is_current == true) {
                  // console.log("this is is current step",f.step_info.name)
                  setselectedStepNameToSwith(f.step_info.name)
                  setSelectedStepNameIdIs(f.step_info.step_id)
                  setflag(true);
                }
              })
            }
            else {
              setcaseDetailsToSwitchStep([]);
            }
            handleSwitchStepModalOpen();
          }
          else if (caseDetailsToSwitchStep.success == false) {
            setcaseDetailsToSwitchStep([]);
            Swal.fire({
              icon: 'error',
              text: "No Steps To Switch Yet",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

    }

    if (Settings == "AssignUser") {
      // console.log("AssignUser in progress");
      // console.log("AssignUser selected case is", JSON.parse(localStorage.getItem('selected_case_for_details')));
      let selectedCaseIs = JSON.parse(localStorage.getItem('selected_case_for_details'));
      let selectedCasecase_id = selectedCaseIs.case_id;

      let LogedInUser = JSON.parse(localStorage.getItem('users'));
      let LogedInUserId = LogedInUser.data.user_details.user_id
      // console.log("LogedInUserId,LogedInUser",LogedInUserId,LogedInUser);

      // console.log("selectedCasecase_id",selectedCasecase_id);
      // setassignNewUserDetails({ ...assignNewUserDetails,
      //   case_id: selectedCasecase_id,
      //   assigned_by_id: LogedInUserId,
      // })

      // to get case assigned user 
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          'case_id': selectedCasecase_id,
          'device_token': device_token, 'session_id': session_id
        })
      };
      fetch(process.env.REACT_APP_GET_CASE_ASSIGNMENT, requestOptions)
        .then((Response) => Response.json())
        .then(async (assignUserDetails) => {
          // console.log("assignUserDetails====",assignUserDetails);
          if (assignUserDetails.success == true) {
            if (assignUserDetails.data.case_assignment) {
              // console.log("assignUserDetails in if",assignUserDetails.data.case_assignment);

              await setassignUserDetails(assignUserDetails.data);
              await setselectedUserToAssignId(assignUserDetails.data.case_assignment.owner_id);
              await setselectedUserToAssignName(assignUserDetails.data.case_assignment.owner);
              setassignNewUserDetails({
                ...assignNewUserDetails,
                case_id: selectedCasecase_id,
                assigned_by_id: LogedInUserId,
                owner_id: assignUserDetails.data.case_assignment.owner_id,
                comments: assignUserDetails.data.case_assignment.comments,
              })
              // setassignNewUserDetails({ ...assignNewUserDetails,
              //   comments: assignUserDetails.data.case_assignment.comments,
              // })
              setswitchRadio(true)

              await setallUsersList(
                allUsersList.map((f) => {
                  if (f.user_id == assignUserDetails.data.case_assignment.owner_id) {
                    console.log("user and owner id matched", f.user_id, assignUserDetails.data.case_assignment.owner_id)
                    f.userSelected = true
                  }
                  else {
                    console.log("user and owner id not matched", f.user_id, assignUserDetails.data.case_assignment.owner_id)
                    f.userSelected = false
                  }
                  return f;
                })
              )

            }
            else {
              setassignUserDetails([]);
              setselectedUserToAssignId();
              setselectedUserToAssignName("");
              // setswitchRadio(true)
            }
          }
          else if (assignUserDetails.success == false) {
            setassignUserDetails([]);
            setselectedUserToAssignId();
            setselectedUserToAssignName("");
            // setswitchRadio(true)
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      handleAssignUserModalOpen();
    }

    if (Settings == "PendingInfo") {
      // console.log("Pending info clicked")
      setOpenPendingInfo(true);
      // console.log("selected case is", JSON.parse(localStorage.getItem('selected_case_for_details')));
      // let selectedCaseIs = JSON.parse(localStorage.getItem('selected_case_for_details'));
      // let selectedCaseId = selectedCaseIs.case_id;
      // console.log("selectedCaseId",selectedCaseId);
    }

    if (Settings == "CloseCase") {
      // console.log("close case clicked")
      setOpenCloseCase(true);
    }
  }

  // close case functions
  const handleCloseCaseModalClose = () => {
    setOpenCloseCase(false);
    setCloseCaseComment("");
    setCloseCaseCommentErr("");
  }

  const validateClosecCase = () => {
    let CloseCaseCommentErr = '';

    // console.log("in validateClosecCase",CloseCaseComment)

    if (CloseCaseComment == "") {
      CloseCaseCommentErr = "Please Add Comment"
    }

    if (CloseCaseCommentErr) {
      setCloseCaseCommentErr(CloseCaseCommentErr);

      return false;
    }
    return true;
  }

  const handelSubmitCloseCase = () => {
    const isValid = validateClosecCase();
    if (isValid) {
      // console.log("CloseCaseComment",CloseCaseComment)
      // to get row
      let selectedCaseIs = JSON.parse(localStorage.getItem('selected_case_for_details'));
      let selectedCasesCaseIdIs = selectedCaseIs.case_id;
      // console.log("selectedCaseId in submit close case",selectedCasesCaseIdIs);
      let rejectCaseObj = ({
        case_id: selectedCasesCaseIdIs,
        case_status: 'close',
        case_alert_message: CloseCaseComment,
        device_token: device_token,
        session_id: session_id,
      })
      setShowProcedure(true);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(rejectCaseObj)
      };
      fetch(process.env.REACT_APP_UPDATE_CASE_STATUS, requestOptions)
        .then((Response) => Response.json())
        .then(casesCloseUpdated => {
          setShowProcedure(false);
          // console.log("casesCloseUpdated on same page",casesCloseUpdated);
          if (casesCloseUpdated.success == true) {
            Swal.fire({
              icon: 'success',
              text: "Case is closed",
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK',
              timer: 5000,
            })
            setOpenCloseCase(false);
            setCloseCaseComment("");
            setCloseCaseCommentErr("");
            setflag(false);
          }
          if (casesCloseUpdated.success == false) {
            Swal.fire({
              icon: 'error',
              text: casesCloseUpdated.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  const bodyCloseCase = (
    <div className={classes.paperModalPendingInfo}>
      <div className={classes.PendingInfoHeaderBackgroud}>
        <Grid container item xs={12}>
          <Grid item xs={11} >
            <h2 style={{ marginLeft: '43%' }}>Close Case</h2>
          </Grid>
          <Grid item xs={1} style={{ marginTop: '1%', }}>
            <CloseIcon onClick={() => handleCloseCaseModalClose()} style={{ fontSize: 20, cursor: 'pointer' }} />
          </Grid>
        </Grid>
      </div>
      <div className={classes.modalBody}>
        <Grid style={{ marginTop: '7%' }}>
          <TextField
            id="comment"
            name="comment"
            variant="outlined"
            autoFocus
            fullWidth
            required
            label="comment"
            value={CloseCaseComment}
            onChange={(e) => setCloseCaseComment(e.target.value)}
          />
        </Grid>
        <div className={classes.validation}>{(CloseCaseComment) ? (<div></div>) : (CloseCaseCommentErr)}</div>

        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '3%' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitCloseCase}
          >
            Submit
          </Button>
          <div style={{ marginLeft: '2%' }}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handleCloseCaseModalClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )

  // pending info functions
  const handlePendingInfoModalClose = () => {
    setOpenPendingInfo(false);
    setPendingInfoComment("");
    setpendingInfoCommentErr("");
  }

  const validatePendingInfo = () => {
    let pendingInfoCommentErr = '';

    // console.log("in validate Assign User",assignNewUserDetails)

    if (PendingInfoComment == "") {
      pendingInfoCommentErr = "Please Add Comment"
    }

    if (pendingInfoCommentErr) {
      setpendingInfoCommentErr(pendingInfoCommentErr);

      return false;
    }
    return true;
  }

  const handelSubmitPendingInfo = () => {
    const isValid = validatePendingInfo();
    if (isValid) {
      // console.log("PendingInfoComment",PendingInfoComment)
      // to get row
      let selectedCaseIs = JSON.parse(localStorage.getItem('selected_case_for_details'));
      let selectedCasesCaseIdIs = selectedCaseIs.case_id;
      // console.log("selectedCaseId in submit pending info",selectedCasesCaseIdIs);
      let rejectCaseObj = ({
        case_id: selectedCasesCaseIdIs,
        case_status: 'pending',
        case_alert_message: PendingInfoComment,
        device_token: device_token,
        session_id: session_id,
      })
      setShowProcedure(true);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(rejectCaseObj)
      };
      fetch(process.env.REACT_APP_UPDATE_CASE_STATUS, requestOptions)
        .then((Response) => Response.json())
        .then(casesPendingDetailsUpdated => {
          setShowProcedure(false);
          // console.log("casesPendingDetailsUpdated on same page",casesPendingDetailsUpdated);
          if (casesPendingDetailsUpdated.success == true) {
            Swal.fire({
              icon: 'success',
              text: "Case in pending state",
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK',
              timer: 5000,
            })
            setOpenPendingInfo(false);
            setPendingInfoComment("");
            setpendingInfoCommentErr("");
            setflag(false);
          }
          if (casesPendingDetailsUpdated.success == false) {
            Swal.fire({
              icon: 'error',
              text: casesPendingDetailsUpdated.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  const bodyPendingInfo = (
    <div className={classes.paperModalPendingInfo}>
      <div className={classes.PendingInfoHeaderBackgroud}>
        <Grid container item xs={12}>
          <Grid item xs={11} >
            <h2 style={{ marginLeft: '43%' }}>Pending Info</h2>
          </Grid>
          <Grid item xs={1} style={{ marginTop: '1%', }}>
            <CloseIcon onClick={() => handlePendingInfoModalClose()} style={{ fontSize: 20, cursor: 'pointer' }} />
          </Grid>
        </Grid>
      </div>
      <div className={classes.modalBody}>
        <Grid style={{ marginTop: '7%' }}>
          <TextField
            id="comment"
            name="comment"
            variant="outlined"
            autoFocus
            fullWidth
            required
            label="comment"
            value={PendingInfoComment}
            onChange={(e) => setPendingInfoComment(e.target.value)}
          />
        </Grid>
        <div className={classes.validation}>{(PendingInfoComment) ? (<div></div>) : (pendingInfoCommentErr)}</div>

        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '3%' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitPendingInfo}
          >
            Submit
          </Button>
          <div style={{ marginLeft: '2%' }}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handlePendingInfoModalClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )

  // show details
  const handleClickShowCaseDetails = async (event, item) => {
    // console.log("show case  clicked is",item);
    setAnchorEl(event.currentTarget);
    await localStorage.setItem('selected_case_for_details', JSON.stringify(item));
    await localStorage.setItem('navigated_toshow_case_from', JSON.stringify("Cases"));
    setslectedCaseForDetailsHere(item)
    setselectedProcessNameToSwitch(item.process)
    setselectedProcessIdToSwitch(item.process_id)
  }

  const handleCloseShowCase = async (Settings) => {
    setAnchorEl(null);
    // console.log("Settings of status in handleClose",Settings);

    if (Settings == "SwitchProcess") {
      // console.log("selected case is", JSON.parse(localStorage.getItem('selected_case_for_details')));
      let selectedCaseIs = JSON.parse(localStorage.getItem('selected_case_for_details'));
      let selectedCaseServiceId = selectedCaseIs.service_id;
      // console.log("selectedCaseServiceId",selectedCaseServiceId);

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          'service_id': selectedCaseServiceId, 'process_type': 'live',
          'device_token': device_token, 'session_id': session_id
        })
      };
      fetch(process.env.REACT_APP_GET_SERVICE_PROCESS_URL, requestOptions)
        .then((Response) => Response.json())
        .then(async (processDetailsByService) => {
          // console.log("processDetailsByService",processDetailsByService);
          if (processDetailsByService.success == true) {
            if (processDetailsByService.data.processes) {
              setprocessDetailsByService(processDetailsByService.data.processes);
            }
            else {
              setprocessDetailsByService([]);
            }
          }
          else if (processDetailsByService.success == false) {
            setprocessDetailsByService([]);
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      //to open modal
      handleSwitchProcessModalOpen();
    }

    if (Settings == "ConfirmCase") {
      console.log("selected case is SwitchProcess", JSON.parse(localStorage.getItem('selected_case_for_details')));
      let selectedCaseIs = JSON.parse(localStorage.getItem('selected_case_for_details'));
      let selectedCasesCaseIdIs = selectedCaseIs.case_id;
      // console.log("selectedCasesCaseIdIs",selectedCasesCaseIdIs)

      let confiremCaseObj = ({
        case_id: selectedCasesCaseIdIs,
        case_status: 'confirm-case',
        device_token: device_token,
        session_id: session_id,
      })
      // console.log("confiremCaseObj",confiremCaseObj)

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: classes.buttonSwalDelete,
          cancelButton: classes.buttonSwalCancel,
        },
        buttonsStyling: false
      })

      let msgHere = "The case is going to be confirm for - Service: " + selectedCaseIs.service + "," + " Priority: " + selectedCaseIs.process
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: msgHere,
        // text: "You won't be able to revert this!",
        // html: "I will close in <b></b> milliseconds.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, confirm it',
        cancelButtonText: 'No, cancel',
        reverseButtons: true
      }).then((result) => {

        if (result.value) {
          setShowProcedure(true);
          const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(confiremCaseObj)
          };
          fetch(process.env.REACT_APP_UPDATE_CASE_STATUS, requestOptions)
            .then((Response) => Response.json())
            .then(casesDetailsUpdated => {
              setShowProcedure(false);
              // console.log("casesDetailsUpdated on same page",casesDetailsUpdated);
              if (casesDetailsUpdated.success == true) {
                Swal.fire({
                  icon: 'success',
                  text: "Case Status Updated Succesfully!!",
                  confirmButtonColor: 'primary',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
                setflag(false);
              }
              if (casesDetailsUpdated.success == false) {
                Swal.fire({
                  icon: 'error',
                  text: casesDetailsUpdated.errors,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
              }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });

        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          // swalWithBootstrapButtons.fire(
          //   'Cancelled',
          //   'Your imaginary file is safe :)',
          //   'error'
          // )
        }
      })
    }

    if (Settings == "RejectCase") {
      // console.log("reject case is clicked");
      setOpenRejectCase(true);
      // let selectedCaseIs = JSON.parse(localStorage.getItem('selected_case_for_details'));
      // let selectedCasesCaseIdIs = selectedCaseIs.case_id;
      // // console.log("selectedCasesCaseIdIs",selectedCasesCaseIdIs)

      // let rejectCaseObj = ({
      //   case_id: selectedCasesCaseIdIs,
      //   case_status: 'reject-case',
      //   device_token: device_token,
      //   session_id: session_id,
      // })
      // // console.log("rejectCaseObj",rejectCaseObj)

      // const swalWithBootstrapButtons = Swal.mixin({
      //   customClass: {
      //     confirmButton: classes.buttonSwalDelete,
      //     cancelButton: classes.buttonSwalCancel,
      //   },
      //   buttonsStyling: false
      // })
      // swalWithBootstrapButtons.fire({
      //   title: 'Are you sure?',
      //   text: "The case will be move to draft state, additional information will be provided",
      //   icon: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#d33',
      //   confirmButtonText: 'Yes, confirm it',
      //   cancelButtonText: 'No, cancel',
      //   reverseButtons: true
      // }).then((result) => {

      //   if (result.value) {
      //     setShowProcedure(true);
      //     const requestOptions = {
      //       method: 'POST',
      //       headers: authHeader(),
      //       body: JSON.stringify(rejectCaseObj)
      //     };
      //     fetch( process.env.REACT_APP_UPDATE_CASE_STATUS , requestOptions)
      //       .then((Response) => Response.json())
      //       .then(casesRejectDetailsUpdated => {
      //         setShowProcedure(false);
      //         console.log("casesRejectDetailsUpdated on same page",casesRejectDetailsUpdated);
      //         if(casesRejectDetailsUpdated.success == true)
      //         {
      //           Swal.fire({
      //             icon: 'success',
      //             text: "Case reverted to draft",
      //             confirmButtonColor: 'primary',
      //             confirmButtonText: 'OK',
      //             timer: 5000,
      //           })
      //           setflag(false);
      //         }
      //         if(casesRejectDetailsUpdated.success == false){
      //           Swal.fire({
      //             icon: 'error',
      //             text: casesRejectDetailsUpdated.errors,
      //             confirmButtonColor: '#d33',
      //             confirmButtonText: 'OK',
      //             timer: 5000,
      //           })
      //         }
      //     })
      //     .catch(err => {
      //       setShowProcedure(false);
      //       Swal.fire({
      //         icon: 'error',
      //         // text: "Something went wrong",
      //         text: "Server Error. Please try again.",
      //         confirmButtonColor: '#d33',
      //         confirmButtonText: 'OK'
      //       })
      //     });

      //   } else if (
      //     /* Read more about handling dismissals below */
      //     result.dismiss === Swal.DismissReason.cancel
      //   ) 
      //   {
      //     // swalWithBootstrapButtons.fire(
      //     //   'Cancelled',
      //     //   'Your imaginary file is safe :)',
      //     //   'error'
      //     // )
      //   }
      // })
    }

    if (Settings == "AssignUser") {
      // setswitchRadio(false);
      // console.log("AssignUser in created");
      // console.log("AssignUser selected case is", JSON.parse(localStorage.getItem('selected_case_for_details')));
      let selectedCaseIs = JSON.parse(localStorage.getItem('selected_case_for_details'));
      let selectedCasecase_id = selectedCaseIs.case_id;

      let LogedInUser = JSON.parse(localStorage.getItem('users'));
      let LogedInUserId = LogedInUser.data.user_details.user_id
      // console.log("LogedInUserId,LogedInUser",LogedInUserId,LogedInUser);

      // console.log("selectedCasecase_id",selectedCasecase_id);
      // setassignNewUserDetails({ ...assignNewUserDetails ,
      //   case_id: selectedCasecase_id,
      //   assigned_by_id: LogedInUserId,
      // })

      // // to get case assigned user 
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          'case_id': selectedCasecase_id,
          'device_token': device_token, 'session_id': session_id
        })
      };
      fetch(process.env.REACT_APP_GET_CASE_ASSIGNMENT, requestOptions)
        .then((Response) => Response.json())
        .then(async (assignUserDetails) => {
          // console.log("assignUserDetails====",assignUserDetails);
          if (assignUserDetails.success == true) {
            if (assignUserDetails.data.case_assignment) {
              // console.log("assignUserDetails in if",assignUserDetails.data.case_assignment);

              await setassignUserDetails(assignUserDetails.data);
              await setselectedUserToAssignId(assignUserDetails.data.case_assignment.owner_id);
              await setselectedUserToAssignName(assignUserDetails.data.case_assignment.owner);
              setassignNewUserDetails({
                ...assignNewUserDetails,
                case_id: selectedCasecase_id,
                assigned_by_id: LogedInUserId,
                owner_id: assignUserDetails.data.case_assignment.owner_id,
                comments: assignUserDetails.data.case_assignment.comments,
              })
              // setassignNewUserDetails({ ...assignNewUserDetails ,
              //   comments: assignUserDetails.data.case_assignment.comments,
              // })
              setswitchRadio(true)


              await setallUsersList(
                allUsersList.map((f) => {
                  if (f.user_id == assignUserDetails.data.case_assignment.owner_id) {
                    // console.log("user and owner id matched",f.user_id,assignUserDetails.data.case_assignment.owner_id)
                    f.userSelected = true
                  }
                  else {
                    // console.log("user and owner id not matched",f.user_id,assignUserDetails.data.case_assignment.owner_id)
                    f.userSelected = false
                  }
                  return f;
                })
              )

            }
            else {
              setassignUserDetails([]);
              setselectedUserToAssignId();
              setselectedUserToAssignName("");
              // setswitchRadio(true)
            }
          }
          else if (assignUserDetails.success == false) {
            setassignUserDetails([]);
            setselectedUserToAssignId();
            setselectedUserToAssignName("");
            // setswitchRadio(true)
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      handleAssignUserModalOpen();
    }

  }

  // reject case
  const handleRejectCaseModalClose = () => {
    setOpenRejectCase(false);
    setRejectCaseCommentErr("");
    setRejectCaseComment("");
  }

  const validateRejectCase = () => {
    let RejectCaseCommentErr = '';

    // console.log("in validate RejectCaseCommentErr",RejectCaseCommentErr)

    if (RejectCaseComment == "") {
      RejectCaseCommentErr = "Please Add Comment"
    }

    if (RejectCaseCommentErr) {
      setRejectCaseCommentErr(RejectCaseCommentErr);

      return false;
    }
    return true;
  }

  const handelSubmitRejectCase = () => {
    const isValid = validateRejectCase();
    if (isValid) {
      // console.log("RejectCaseComment",RejectCaseComment)

      let selectedCaseIs = JSON.parse(localStorage.getItem('selected_case_for_details'));
      let selectedCasesCaseIdIs = selectedCaseIs.case_id;
      // console.log("selectedCasesCaseIdIs",selectedCasesCaseIdIs)
      let rejectCaseObj = ({
        case_id: selectedCasesCaseIdIs,
        case_status: 'reject-case',
        case_alert_message: RejectCaseComment,
        device_token: device_token,
        session_id: session_id,
      })
      setShowProcedure(true);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(rejectCaseObj)
      };
      fetch(process.env.REACT_APP_UPDATE_CASE_STATUS, requestOptions)
        .then((Response) => Response.json())
        .then(casesRejectDetailsUpdated => {
          setShowProcedure(false);
          // console.log("casesRejectDetailsUpdated on same page",casesRejectDetailsUpdated);
          if (casesRejectDetailsUpdated.success == true) {
            Swal.fire({
              icon: 'success',
              text: "Case reverted to draft",
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK',
              timer: 5000,
            })
            setflag(false);
            setOpenRejectCase(false);
            setRejectCaseCommentErr("");
            setRejectCaseComment("");
          }
          if (casesRejectDetailsUpdated.success == false) {
            Swal.fire({
              icon: 'error',
              text: casesRejectDetailsUpdated.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  const bodyRejectCase = (
    <div className={classes.paperModalPendingInfo}>
      <div className={classes.PendingInfoHeaderBackgroud}>
        <Grid container item xs={12}>
          <Grid item xs={11} >
            <h2 style={{ marginLeft: '43%' }}>Revert to Draft</h2>
          </Grid>
          <Grid item xs={1} style={{ marginTop: '1%', }}>
            <CloseIcon onClick={() => handleRejectCaseModalClose()} style={{ fontSize: 20, cursor: 'pointer' }} />
          </Grid>
        </Grid>
      </div>
      <div className={classes.modalBody}>
        <Grid style={{ marginTop: '7%' }}>
          <TextField
            id="comment"
            name="comment"
            variant="outlined"
            autoFocus
            fullWidth
            required
            label="comment"
            value={RejectCaseComment}
            onChange={(e) => setRejectCaseComment(e.target.value)}
          />
        </Grid>
        <div className={classes.validation}>{(PendingInfoComment) ? (<div></div>) : (RejectCaseCommentErr)}</div>

        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '3%' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitRejectCase}
          >
            Submit
          </Button>
          <div style={{ marginLeft: '2%' }}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handleRejectCaseModalClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )



  // Assign User
  const handleAssignUserModalOpen = () => {
    // console.log("in handleAssignUserModalOpen");
    // setswitchRadio(false)
    setopenAssignUser(true);
  }

  const handleAssignUserModalClose = async () => {
    // console.log("in handleAssignUserModalClose");
    setopenAssignUser(false);
    setShowProcedure(false);
    // setcommentErr("");
    setassignUserErr("");
    setassignNewUserDetails({
      case_id: "",
      owner_id: "",
      assigned_by_id: "",
      comments: "",
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    await setallUsersList(
      allUsersList.map((f) => {
        f.userSelected = false
        return f;
      })
    )
    setflag(false)
    // setselectedUserToAssignId();
    // setselectedUserToAssignName("");
  }

  const handleChangeAssignUserRadio = async (e, assign_by_id, assign_by_name) => {

    let selectedCaseIs = JSON.parse(localStorage.getItem('selected_case_for_details'));
    let selectedCasecase_id = selectedCaseIs.case_id;

    let LogedInUser = JSON.parse(localStorage.getItem('users'));
    let LogedInUserId = LogedInUser.data.user_details.user_id
    // console.log("LogedInUserId,LogedInUser",LogedInUserId,LogedInUser);

    // console.log("handleChangeAssignUserRadio",e,assign_by_id,assign_by_name )
    await setselectedUserToAssignId(assign_by_id);
    await setselectedUserToAssignName(assign_by_name);
    setassignNewUserDetails({
      ...assignNewUserDetails,
      case_id: selectedCasecase_id,
      assigned_by_id: LogedInUserId,
      owner_id: assign_by_id,
    })

    await setallUsersList(
      allUsersList.map((f) => {
        if (f.user_id == assign_by_id) {
          f.userSelected = true
        }
        else {
          f.userSelected = false
        }
        return f;
      })
    )
  }

  const validateAssignUser = () => {
    // let commentErr = '';
    let assignUserErr = '';

    // console.log("in validate Assign User",assignNewUserDetails)

    // if( !assignNewUserDetails.comments ){
    //   commentErr= "Please Enter Comment"
    // }
    if (!assignNewUserDetails.owner_id) {
      assignUserErr = "Please Select User"
    }

    if (assignUserErr) {
      // setcommentErr(commentErr);
      setassignUserErr(assignUserErr);

      return false;
    }
    return true;
  }

  const handelSubmitAssignUser = () => {
    // console.log("in handelSubmitAssignUser assignNewUserDetails,selectedUserToAssignId",
    // assignNewUserDetails,selectedUserToAssignId);

    const isValid = validateAssignUser();
    if (isValid) {
      setShowProcedure(true);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(assignNewUserDetails)
      };
      return fetch(process.env.REACT_APP_ASSIGN_CASE, requestOptions)
        .then((Response) => Response.json())
        .then(userAssignDetails => {
          setShowProcedure(false);
          // console.log("userAssignDetails on same page",userAssignDetails);
          if (userAssignDetails.success == true) {
            Swal.fire({
              icon: 'success',
              text: "User Assign Succesfully",
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK'
            })
            setopenAssignUser(false);
            // setcommentErr("");
            setassignUserErr("");
            setassignNewUserDetails({
              case_id: "",
              owner_id: "",
              assigned_by_id: "",
              comments: "",
              device_token: JSON.parse(localStorage.getItem('device_token')),
              session_id: JSON.parse(localStorage.getItem('session_id')),
            })
            setallUsersList(
              allUsersList.map((f) => {
                f.userSelected = false
                return f;
              })
            )
            // setselectedUserToAssignId();
            // setselectedUserToAssignName("");
          }
          if (userAssignDetails.success == false || userAssignDetails.success == null) {
            setopenAssignUser(true);
            Swal.fire({
              icon: 'error',
              text: userAssignDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  const bodyAssignUser = (
    <div className={classes.paperModalAssigncaseOwner}>
      <div className={classes.addNewBusinessHeaderBackgroud}>
        {/* <h2>Assign User</h2> */}
        <Grid container item xs={12}>
          <Grid item xs={11} >
            <h2 style={{ marginLeft: '33%' }}>Assign Case Owner</h2>
          </Grid>
          {/* <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelAssignUserMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid> */}
          <Grid item xs={1} style={{ marginTop: '1%', }}>
            <CloseIcon onClick={() => handleAssignUserModalClose()} style={{ fontSize: 20, cursor: 'pointer' }} />
          </Grid>
        </Grid>
      </div>
      <div className={classes.modalBody} style={{ position: 'absolute' }}>
        <Grid style={{ marginTop: '4%' }}>
          <TextField
            id="comments"
            name="comments"
            variant="outlined"
            autoFocus
            fullWidth
            // required
            label="comments"
            value={assignNewUserDetails.comments}
            onChange={(e) => setassignNewUserDetails({ ...assignNewUserDetails, comments: e.target.value })}
          />
        </Grid>
        {/* <div className={classes.validation}>{(assignNewUserDetails.comments)?(<div></div>):(commentErr)}</div> */}


        <Grid style={{ marginTop: '6%' }}>
          <Grid className={classes.subHeadingLabelSelectUser} >
            Select Owner
          </Grid>
          {
            (switchRadio == true) ?
              (
                // allUsersList && allUsersList.map(( item, index ) => (
                //   console.log("selectedUserToAssignId, selectedUserToAssignName in modal",selectedUserToAssignId, 
                //   selectedUserToAssignName),
                //   <RadioGroup
                //     aria-label="position"
                //     name="position"
                //     // defaultValue={selectedUserToAssignName}
                //     value={selectedUserToAssignName}
                //     onChange={ (e) => handleChangeAssignUserRadio(e, item.user_id , item.name)}
                //   >   
                //   <div className={classes.customRadioButton}>
                //     <FormControlLabel
                //       value={item.name}     
                //       classes={{ label: classes.label }}
                //       control={<Radio size="small" color="primary" />}
                //       label={item.name}    
                //     />
                //   </div>
                //   </RadioGroup>
                // ))
                allUsersList && allUsersList.map((item, index) => (
                  // console.log("selectedUserToAssignId, selectedUserToAssignName in modal",
                  // selectedUserToAssignId, selectedUserToAssignName),
                  // console.log("allUsersList item",item),
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    // defaultValue={selectedUserToAssignName}
                    // value={selectedUserToAssignName}
                    value={item.userSelected == true ? item.name : null}
                    onChange={(e) => handleChangeAssignUserRadio(e, item.user_id, item.name)}
                  >
                    <div className={classes.customRadioButton}>
                      <FormControlLabel
                        value={item.name}
                        classes={{ label: classes.label }}
                        control={<Radio size="small" color="primary" />}
                        label={item.name}
                      />
                    </div>
                  </RadioGroup>
                ))
              )
              : (<Grid></Grid>)
          }
          <div className={classes.validation}>{(assignNewUserDetails.owner_id) ? (<div></div>) : (assignUserErr)}</div>

        </Grid>

        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '2%' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitAssignUser}
          >
            Assign Owner
          </Button>
          <div style={{ marginLeft: '2%' }}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handleAssignUserModalClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )

  // switch process
  const handleSwitchProcessModalOpen = () => {
    // console.log("in handleSwitchProcessModalOpen");
    setopenSwitchProcess(true);
  }

  const handleSwitchProcessModalClose = () => {
    setopenSwitchProcess(false);
    setswitchProcessErr();
  }

  const handleChangeSwichProcessRadio = async (e, processId, processName) => {
    // console.log("handleChangeSwichProcessRadio e, processId,process",e, processId,process);
    await setselectedProcessToSwitch(processId);
    await setselectedProcessNameToSwitch(processName);
    await setselectedProcessIdToSwitch(processId)
  }

  const validate = () => {
    let switchProcessErr = '';
    // if(!selectedProcessToSwitch)  {
    if (!selectedProcessIdToSwitch) {
      switchProcessErr = 'Please Select Process To Switch';
    }
    if (switchProcessErr) {
      setswitchProcessErr(switchProcessErr);
      return false;
    }

    return true;
  }

  const handelSubmitSwitchProcess = () => {
    // console.log("selectedProcessToSwitch",selectedProcessToSwitch);
    // console.log("slectedCaseForDetailsHere",slectedCaseForDetailsHere);
    const isValid = validate();
    if (isValid) {
      setShowProcedure(true);
      setopenSwitchProcess(false);
      let switchProcessObj = ({
        case_id: slectedCaseForDetailsHere.case_id,
        // process_id:selectedProcessToSwitch,
        process_id: selectedProcessIdToSwitch,
        // device_token: JSON.parse(localStorage.getItem('device_token')),
        // session_id: JSON.parse(localStorage.getItem('session_id')),
        device_token: device_token,
        session_id: session_id,
      });
      // console.log("switchProcessObj",switchProcessObj);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(switchProcessObj)
      };
      fetch(process.env.REACT_APP_SWITCH_PROCESS, requestOptions)
        .then((Response) => Response.json())
        .then(async (processSwitechedDetails) => {
          setShowProcedure(false);
          // console.log("processSwitechedDetails",processSwitechedDetails);
          if (processSwitechedDetails.success == true) {
            Swal.fire({
              icon: 'success',
              text: "Process Switched Succesfully!!",
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK',
              timer: 5000,
            })
            setselectedProcessToSwitch();
            setselectedProcessNameToSwitch();
            setselectedProcessIdToSwitch();
            setswitchProcessErr();
            setflag(false);
          }
          else if (processSwitechedDetails.success == false) {
            Swal.fire({
              icon: 'error',
              text: processSwitechedDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            }).then(function (result) {
              // console.log("result of swal",result)
              setopenSwitchProcess(true);
            })
          }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }

  }

  const bodySwitchProcess = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}>
        {/* <h2>Switch Process</h2> */}
        <Grid container item xs={12}>
          <Grid item xs={11} >
            <h2 style={{ marginLeft: '43%' }}>Switch Process</h2>
          </Grid>
          <Grid item xs={1} style={{ marginTop: '1%', }}>
            <CloseIcon onClick={() => handleSwitchProcessModalClose()} style={{ fontSize: 20, cursor: 'pointer' }} />
          </Grid>
        </Grid>
      </div>
      <div className={classes.modalBody}>
        {
          processDetailsByService && processDetailsByService.map((item, index) => (
            <RadioGroup
              aria-label="position"
              name="position"
              // value={selectedProcessNameToSwitch}
              value={selectedProcessIdToSwitch}
              onChange={(e) => handleChangeSwichProcessRadio(e, item.process_id, item.name)}
            >
              <div className={classes.customRadioButton}>
                <FormControlLabel
                  value={item.process_id}
                  // value={item.name}     
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label={item.name}
                />
              </div>
            </RadioGroup>
          ))
        }
        <div className={classes.validation}>{(selectedProcessToSwitch) ? (<div></div>) : (switchProcessErr)}</div>

        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '2%' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitSwitchProcess}
          >
            Switch Process
          </Button>
          <div style={{ marginLeft: '2%' }}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handleSwitchProcessModalClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )

  // edit details when in draft mode
  const handleClickEditCaseAdminClient = async (event, item) => {
    setAnchorInitiated2(event.currentTarget);
    // console.log("item ",item);
    await localStorage.setItem('selected_case_for_edit', JSON.stringify(item));
  };

  const handleCloseEditCaseClient = (Settings, item) => {
    setAnchorInitiated2(null);
    // console.log("Settings of status in handleClose",Settings);
    // console.log("item of status in handleClose",item);
  };

  // edit details
  const handleClickEditCaseAdmin = async (event, item) => {
    console.log("handleClickEditCaseAdmin clicked")
    setAnchorInitiated(event.currentTarget);
    // console.log("item ",item);
    await localStorage.setItem('selected_case_for_edit', JSON.stringify(item));
  };

  const handleCloseEditCase = (Settings, item) => {
    setAnchorInitiated(null);
    // console.log("Settings of status in handleClose",Settings);
    // console.log("item of status in handleClose",item);
  };

  // switch step
  const handleSwitchStepModalOpen = () => {
    setopenSwitchStep(true);
  }

  const handleSwitchStepModalClose = () => {
    setopenSwitchStep(false);
    setswitchStepErr();
  }

  const handleSwichStepMinimize = () => {
    setopenSwitchStep(false);
  }

  const handleSwichStepClose = () => {
    setopenSwitchStep(false);
    setswitchStepErr();
  }

  const handleChangeSwichStepRadio = async (e, step_name, step_id) => {
    // console.log("e, step_name, step_id is",e, step_name,step_id)
    setselectedStepNameToSwith(step_name)
    setSelectedStepNameIdIs(step_id)

    await setcaseDetailsToSwitchStep(
      caseDetailsToSwitchStep.map((f) => {
        if (f.step_info.step_id === step_id) {
          f.is_current = true
        }
        return f;
      })
    );
  }

  const validateStep = () => {
    let switchStepErr = '';
    if (!selectedStepNameToSwith) {
      switchStepErr = 'Please Select Step To Switch';
    }
    if (switchStepErr) {
      setswitchStepErr(switchStepErr);
      return false;
    }

    return true;
  }

  const handelSubmitSwitchStep = () => {
    // console.log("SelectedStepNameIdIs,slectedCaseForDetailsHere.case_id",SelectedStepNameIdIs,slectedCaseForDetailsHere.case_id);

    const isValid = validateStep();
    // if(isValid){
    setShowProcedure(true);
    setopenSwitchStep(false);
    let switchStepObj = ({
      case_id: slectedCaseForDetailsHere.case_id,
      step_id: SelectedStepNameIdIs,
      device_token: device_token,
      session_id: session_id,
    });
    // console.log("switchStepObj",switchStepObj);
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(switchStepObj)
    };
    fetch(process.env.REACT_APP_SWITCH_CASE_STEP, requestOptions)
      .then((Response) => Response.json())
      .then(async (StepSwitechedDetails) => {
        setShowProcedure(false);
        // console.log("StepSwitechedDetails",StepSwitechedDetails);
        if (StepSwitechedDetails.success == true) {
          Swal.fire({
            icon: 'success',
            text: "Step Switched Succesfully!!",
            confirmButtonColor: 'primary',
            confirmButtonText: 'OK',
            timer: 5000,
          })
          setselectedStepNameToSwith();
          setSelectedStepNameIdIs();
          setswitchStepErr();
          setflag(false);
        }
        else if (StepSwitechedDetails.success == false) {
          Swal.fire({
            icon: 'error',
            text: StepSwitechedDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          }).then(function (result) {
            // console.log("result of swal",result)
            setopenSwitchStep(true);
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    // }
  }

  const bodySwitchStep = (
    <div className={classes.paperModalSwitchStep}>
      <div className={classes.SwitchStepHeaderBackgroud}>
        {/* <h2>Switch Step</h2> */}
        <Grid container item xs={12}>
          <Grid item xs={11} >
            <h2 style={{ marginLeft: '43%' }}>Switch Step</h2>
          </Grid>
          {/* <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handleSwichStepMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid> */}
          <Grid item xs={1} style={{ marginTop: '1%', }}>
            <CloseIcon onClick={() => handleSwichStepClose()} style={{ fontSize: 20, cursor: 'pointer' }} />
          </Grid>
        </Grid>
      </div>
      <div className={classes.modalBody}>
        {
          caseDetailsToSwitchStep && caseDetailsToSwitchStep.map((item, index) => (
            <RadioGroup
              aria-label="position"
              name="position"
              value={selectedStepNameToSwith && selectedStepNameToSwith}
              // defaultValue={ item.is_current == true ? item.step_info.name : null }
              onChange={(e) => handleChangeSwichStepRadio(e, item.step_info.name, item.step_info.step_id)}
            >
              <div className={classes.customRadioButton}>
                <FormControlLabel
                  value={item.step_info.name}
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label={item.step_info.name}
                />
              </div>
            </RadioGroup>
          ))
        }
        <div className={classes.validation}>{(selectedStepNameToSwith) ? (<div></div>) : (switchStepErr)}</div>

        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '2%' }}>
          {/* {
            ( SelectedStepNameIdIs )? 
            ( */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitSwitchStep}
          >
            Switch Step
          </Button>
          {/* ):
            (<div></div>)
          } */}

          <div style={{ marginLeft: '2%' }}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handleSwitchStepModalClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )

  const handleFilterModalOpen = () => {
    setOpenFilter(true);

    // setTagFilter([])
    // setAssignUserFilter([])

  };

  const handleFilterModalClose = () => {
    setOpenFilter(false);

  };

  const handleFilterModalReset = async () => {
    setflag(false)
    setMultiselectRefresh(false);
    setFilterIsApplied(false);
    setAssignUserFilter([]);
    setTagFilter([]);
    setServiceFilter([]);
    setClientsFilter([]);
    setRoleFilter([]);
    setClientGroupsFilter([]);

    setAssignUserFilterNames([]);
    setTagFilterNames([]);
    setServiceFilterNames([]);
    setClientsFilterNames([]);
    setRoleFilterNames([]);
    setClientGroupsFilterNames([]);

    // sort by
    await setSortByArray(
      SortByArray.map((f) => {
        f.selected = false;
        return f;
      })
    )
    // priority
    await setPriorityArray(
      PriorityArray.map((f) => {
        f.selected = false;
        return f;
      })
    )
    // tags
    await setallTagDetails(
      allTagDetails.map((f) => {
        f.selected = false;
        return f;
      })
    )
    // assigned people
    await setallUsersList(
      allUsersList.map((f) => {
        f.selected = false;
        return f;
      })
    )
    setMultiselectRefresh(true);

  }

  const handelSubmitApplyFilter = () => {
    setOpenFilter(false);
    setShowProcedure(true);
    setFilterIsApplied(true);
    // sort
    let SortBySelectedHere = "";
    SortByArray.map((f) => {
      if (f.selected == true) {
        // console.log("true f is",f)
        SortBySelectedHere = f.value;
      }
    })
    // console.log("SortBySelectedHere",SortBySelectedHere);
    //priority
    let PrioritySelectedHere = "";
    PriorityArray.map((f) => {
      if (f.selected == true) {
        // console.log("true f is",f)
        PrioritySelectedHere = f.value;
      }
    })
    // console.log("PrioritySelectedHere",PrioritySelectedHere);
    // tag
    const tagsForFilter = [];
    allTagDetails.map((f) => {
      if (f.selected == true) {
        // console.log("true f is",f)
        tagsForFilter.push(f.tag_id);
      }
    })
    // console.log("tagsForFilter is",tagsForFilter)
    // assigned people allUsersList
    const assignedPeopleForFilter = [];
    allUsersList.map((f) => {
      if (f.selected == true) {
        // console.log("true f is",f)
        assignedPeopleForFilter.push(f.user_id);
      }
    })
    // console.log("assignedPeopleForFilter is",assignedPeopleForFilter,AssignUserFilter)
    // console.log("AssignUserFilter TagFilter is in submit",AssignUserFilter, TagFilter)

    let per_page_here = 100;
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      // body: JSON.stringify({'search_str': '', 'business_id': business_id, 
      // 'device_token': device_token, 'session_id': session_id,
      // 'sort_by': SortBySelectedHere, 'priority': PrioritySelectedHere, 'tags': tagsForFilter, 
      // 'assigned_users': assignedPeopleForFilter })
      body: JSON.stringify({
        'search_str': '', 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id,
        'sort_by': SortBySelectedHere, 'priority': PrioritySelectedHere, 'tags': TagFilter,
        'services':ServiceFilter, 'clients':ClientsFilter,
        // 'roles':RoleFilter, 'client_groups': ClientGroupsFilter,
        'assigned_users': AssignUserFilter, "client_type": "ALL",
        'page_number': 1, 'page_size': per_page_here
      })
    };

    fetch(process.env.REACT_APP_SEARCH_CASES, requestOptions)
      .then((Response) => Response.json())
      .then(async (SearchDetailsFilter) => {
        setShowProcedure(false);
        console.log("SearchDetailsFilter is==",SearchDetailsFilter.data.pagination);
        if (SearchDetailsFilter.data.search_results) {
          setPage(0)
          setRowsPerPage(per_page_here)
          // console.log("SearchDetailsFilter",SearchDetailsFilter.data.search_results); 
          setcasesList(SearchDetailsFilter.data.search_results.cases);

          // setpaginationDetails({...paginationDetails, total_cases:SearchDetailsFilter.data.search_results.total_cases })

          // sort by
          // await setSortByArray(
          //   SortByArray.map((f)=> {
          //     f.selected = false;
          //     return f ;
          //   })
          // )

          // priority
          // await setPriorityArray(
          //   PriorityArray.map((f)=> {
          //     f.selected = false;
          //     return f ;
          //   })
          // )

          // tag
          // await setallTagDetails(
          //   allTagDetails.map((f)=> {
          //     f.selected = false;
          //     return f ;
          //   })
          // )

          // assigned people
          // await setallUsersList(
          //   allUsersList.map((f)=> {
          //     f.selected = false;
          //     return f ;
          //   })
          // )
        }
        if (SearchDetailsFilter.data.pagination) {
          setpaginationDetails(SearchDetailsFilter.data.pagination)

          setrecentPageNumberFromApi(SearchDetailsFilter.data.pagination.current_page)

          settotalNumberOfPagesFromApi(SearchDetailsFilter.data.pagination.total_pages)


        }
        if (SearchDetailsFilter.success == false) {
          setcasesList([])
          let total_cases_here = 0
          setpaginationDetails({...paginationDetails, total_cases:total_cases_here })
          Swal.fire({
            icon: 'error',
            // text: SearchDetailsFilter.errors,
            // text: "Something went wrong, retry with different filters",
            text: "No results found for search, please update search criteria and try again",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  // sort
  const handleSortClick = async (item) => {
    // console.log(item)
    await setSortByArray(
      SortByArray.map((f) => {
        if (f.value === item.value) {
          f.selected = !f.selected;
        }
        else {
          f.selected = false;
        }
        return f;
      })
    )
  }
  // priority
  const handlePriorityClick = async (item) => {
    // console.log(item)
    await setPriorityArray(
      PriorityArray.map((f) => {
        if (f.value === item.value) {
          f.selected = !f.selected;
        }
        else {
          f.selected = false;
        }
        return f;
      })
    )
  }
  // tag
  const handleFilterTagClick = async (item) => {
    // console.log("items in tag filter",item);
    await setallTagDetails(
      allTagDetails.map((f) => {
        if (f.tag_id === item.tag_id) {
          f.selected = !f.selected;
        }
        return f;
      })
    )
  }
  // assigned people
  const handleFilterAssignedPeopleClick = async (item) => {
    // console.log("items in assigned people filter",item);
    await setallUsersList(
      allUsersList.map((f) => {
        if (f.user_id === item.user_id) {
          f.selected = !f.selected;
        }
        return f;
      })
    )
  }

  const bodyFilter = (
    <div className={classes.paperModalFilter}>
      <div >
        <Grid container item xs={12}>
          <Grid item xs={10} style={{ marginLeft: '5%' }}>
            <h2>Filters</h2>
          </Grid>
          <Grid item xs={1} style={{ marginTop: '3%', marginLeft: '1%' }}>
            <CloseIcon onClick={() => handleFilterModalClose()} style={{ cursor: 'pointer' }} />
          </Grid>
        </Grid>
      </div>
      <div className={classes.modalBodyFilters} style={{ position: 'absolute' }}>
        <Grid>
          {/* sort by */}
          {/* Sort by (can choose only one) */}

          <Grid container item xs={12}> 
            <Grid container item xs={6}> 
              <Grid item xs={12} className={classes.subHeadingLabel}>
                Sort by
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  {
                    SortByArray.map((item, index) => (
                      (item.selected == true) ?
                        (
                          <Grid item className={classes.marginRight}>
                            <Button
                              className={classes.textTransformNone}
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() => handleSortClick(item)}
                            >
                              {item.name}
                            </Button>
                          </Grid>
                        ) :
                        (
                          <Grid item className={classes.marginRight}>
                            <Button
                              className={classes.textTransformNone}
                              size="small"
                              variant="outlined"
                              color="black"
                              onClick={() => handleSortClick(item)}
                            >
                              {item.name}
                            </Button>
                          </Grid>
                        )
                    ))
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={6}> 
              {/* Priority */}
              <Grid item xs={12} className={classes.subHeadingLabel}>
                {/* Priority (can choose only one) */}
                Priority
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  {
                    PriorityArray.map((item, index) => (
                      (item.selected == true) ?
                        (
                          <Grid item className={classes.marginRight}>
                            <Button
                              className={classes.textTransformNone}
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() => handlePriorityClick(item)}
                            >
                              {item.name}
                            </Button>
                          </Grid>
                        ) :
                        (
                          <Grid item className={classes.marginRight}>
                            <Button
                              className={classes.textTransformNone}
                              size="small"
                              variant="outlined"
                              color="black"
                              onClick={() => handlePriorityClick(item)}
                            >
                              {item.name}
                            </Button>
                          </Grid>
                        )
                    ))
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          

          {/* Assigned people */}
          <Grid item xs={12} className={classes.subHeadingLabeltwo}>
            {/* Assigned people (can choose multiple) */}
            Assigned To
          </Grid>
          <Grid>
            {
              (MultiselectRefresh == true) ?
                (
                  <Multiselect
                    data={allUsersList}
                    defaultValue={AssignUserFilterNames}
                    // defaultValue={AssignUserFilter}
                    placeholder="Select User"
                    valueField='user_id'
                    textField='name'
                    onChange={async (event) => {
                      // console.log("event of multiselect",event);
                      var joined = [];
                      var joinedNames = [];
                      // console.log("length : "+event.length);
                      if (event.length === 0) {
                        await setAssignUserFilter([])
                      }
                      event.map(async (data) => {
                        // console.log("data in eve map : "+JSON.stringify(data));
                        joined = joined.concat(data.user_id);
                        // joinedNames = joinedNames.concat(data.name);
                        joinedNames = joinedNames.concat(data);
                        // console.log("data join : ",joined);
                        await setAssignUserFilter(joined)
                        await setAssignUserFilterNames(joinedNames)
                        // console.log("check this AssignUserFilter",AssignUserFilter)

                        // assignedUsersDefault = assignedUsersDefault.concat(data)
                        // console.log("assignedUsersDefault data join : ",assignedUsersDefault);
                      })
                    }}
                  />
                ) :
                (
                  <Grid></Grid>
                )
            }
          </Grid>

          {/* Tags */}
          <Grid item xs={12} className={classes.subHeadingLabeltwo}>
            {/* Tags (can choose multiple) */}
            Tags
          </Grid>
          <Grid>
            {
              (MultiselectRefresh == true) ?
                (
                  <Multiselect
                    data={allTagDetails}
                    defaultValue={TagFilterNames}
                    placeholder="Select Tags"
                    valueField='tag_id'
                    textField='name'
                    onChange={async (event) => {
                      // console.log("event of multiselect",event);
                      var joined = [];
                      var joinedNames = [];
                      // console.log("length : "+event.length);
                      if (event.length === 0) {
                        await setTagFilter([])
                      }
                      event.map(async (data) => {
                        // console.log("data in eve map : "+JSON.stringify(data));
                        joined = joined.concat(data.tag_id);
                        joinedNames = joinedNames.concat(data);
                        // console.log("data join : ",joined);
                        await setTagFilter(joined)
                        await setTagFilterNames(joinedNames)
                        // console.log("check this TagFilter",TagFilter)
                      })
                    }}
                  />
                ) :
                (
                  <Grid></Grid>
                )
            }
            {/* {allTagDetails && allTagDetails.map((item, index) =>
            <Grid item className={classes.marginRight} style={{marginTop:'1%'}}>
              <Button
                className={classes.tagButtonStyle}
                size="small"
                variant="outlined"
                style={{backgroundColor: item.color, color: '#FFFFFF'}}
                endIcon={ item.selected == true ?  <CheckIcon/> : null }
                onClick={()=> handleFilterTagClick(item)}
              >
                {item.name}
              </Button>
            </Grid>
          )} */}
          </Grid>

          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} >
              {/* Priority */}
              <Grid item xs={12} className={classes.subHeadingLabeltwo}>
                {/* Priority (can choose only one) */}
                Services
              </Grid>
              <Grid item xs={12}>
                <Grid >
                {
                  (MultiselectRefresh == true) ?
                    (
                      <Multiselect
                        data={allServicesDetails}
                        defaultValue={ServiceFilterNames}
                        placeholder="Select Services"
                        valueField='category_id'
                        textField='name'
                        onChange={async (event) => {
                          // console.log("event of multiselect",event);
                          var joined = [];
                          var joinedNames = [];
                          // console.log("length : "+event.length);
                          if (event.length === 0) {
                            await setServiceFilter([])
                          }
                          event.map(async (data) => {
                            // console.log("data in eve map : "+JSON.stringify(data));
                            joined = joined.concat(data.category_id);
                            joinedNames = joinedNames.concat(data);
                            // console.log("data join : ",joined);
                            await setServiceFilter(joined)
                            await setServiceFilterNames(joinedNames)
                            // console.log("check this TagFilter",TagFilter)
                          })
                        }}
                      />
                    ) :
                    (
                      <Grid></Grid>
                    )
                }
                </Grid>
              </Grid>
            </Grid>

          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} >
              <Grid item xs={12} className={classes.subHeadingLabeltwo}>
                Clients
              </Grid>
              <Grid item xs={12}>
                <Grid >
                {
                  (MultiselectRefresh == true) ?
                    (
                      <Multiselect
                        data={ClientDetails}
                        defaultValue={ClientsFilterNames}
                        placeholder="Select Clients"
                        valueField='client_id'
                        // textField='first_name'
                        textField='fullNameOrOrg'
                        // textField={( ClientDetails && ClientDetails.client_type === 'business' ? 'org_name' : 'first_name' )}
                        onChange={async (event) => {
                          // console.log("event of multiselect",event);
                          var joined = [];
                          var joinedNames = [];
                          // console.log("length : "+event.length);
                          if (event.length === 0) {
                            await setClientsFilter([])
                          }
                          event.map(async (data) => {
                            // console.log("data in eve map : "+JSON.stringify(data));
                            joined = joined.concat(data.client_id);
                            joinedNames = joinedNames.concat(data);
                            // console.log("data join : ",joined);
                            await setClientsFilter(joined)
                            await setClientsFilterNames(joinedNames)
                            // console.log("check this TagFilter",TagFilter)
                          })
                        }}
                      />
                    ) :
                    (
                      <Grid></Grid>
                    )
                }
                </Grid>
              </Grid>
            </Grid>
        
          </Grid>

            {/* <Grid item xs={6}>
              <Grid item xs={12} className={classes.subHeadingLabeltwo}>
                Roles
              </Grid>
              <Grid item xs={12}>
                <Grid >
                {
                  (MultiselectRefresh == true) ?
                    (
                      <Multiselect
                        data={allRolesDetails}
                        defaultValue={RoleFilterNames}
                        placeholder="Select Roles"
                        valueField='role_id'
                        textField='name'
                        onChange={async (event) => {
                          // console.log("event of multiselect",event);
                          var joined = [];
                          var joinedNames = [];
                          // console.log("length : "+event.length);
                          if (event.length === 0) {
                            await setRoleFilter([])
                          }
                          event.map(async (data) => {
                            // console.log("data in eve map : "+JSON.stringify(data));
                            joined = joined.concat(data.role_id);
                            joinedNames = joinedNames.concat(data);
                            // console.log("data join : ",joined);
                            await setRoleFilter(joined)
                            await setRoleFilterNames(joinedNames)
                            // console.log("check this TagFilter",TagFilter)
                          })
                        }}
                      />
                    ) :
                    (
                      <Grid></Grid>
                    )
                }
                </Grid>
              </Grid>

            </Grid> */}
            
          </Grid>

          {/* <Grid container item xs={12} spacing={1}>
            <Grid item xs={6}>
              <Grid item xs={12} className={classes.subHeadingLabeltwo}>
                Client Groups
              </Grid>
              <Grid item xs={12}>
                <Grid >
                {
                  (MultiselectRefresh == true) ?
                    (
                      <Multiselect
                        data={allClientGroupDetails}
                        defaultValue={ClientGroupsFilterNames}
                        placeholder="Select Groups"
                        valueField='client_group_id'
                        textField='name'
                        onChange={async (event) => {
                          // console.log("event of multiselect",event);
                          var joined = [];
                          var joinedNames = [];
                          // console.log("length : "+event.length);
                          if (event.length === 0) {
                            await setClientGroupsFilter([])
                          }
                          event.map(async (data) => {
                            // console.log("data in eve map : "+JSON.stringify(data));
                            joined = joined.concat(data.client_group_id);
                            joinedNames = joinedNames.concat(data);
                            // console.log("data join : ",joined);
                            await setClientGroupsFilter(joined)
                            await setClientGroupsFilterNames(joinedNames)
                            // console.log("check this TagFilter",TagFilter)
                          })
                        }}
                      />
                    ) :
                    (
                      <Grid></Grid>
                    )
                }
                </Grid>
          </Grid>
            </Grid>

            <Grid item xs={6}>
            </Grid>
            
          </Grid> */}

          

          {/* Case Id Pattern */}
          {/* <Grid item xs={12} className={classes.subHeadingLabeltwo}>
          Case Id Pattern
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid> */}


          {/* <Grid container style={{alignItems:'center', alignContent:'center',}}>
          {allUsersList && allUsersList.map((item, index) =>
            <Grid item className={classes.marginRight} style={{cursor:"pointer", marginTop:'2%',
            justifyContent: 'center',}}
              onClick={()=> handleFilterAssignedPeopleClick(item)}
            >
              <Avatar src="/static/images/avatar/1.jpg" />
              {
                  ( item.selected == true)?
                  (
                    <Grid style={{fontSize:15,backgroundColor:'#3765ad'}}>{item.name}</Grid>
                  ):
                  (
                    <Grid style={{fontSize:15, }}>{item.name}</Grid>
                  )
                }
              
              {/* <Grid item xs={1} style={{ padding: 9, marginLeft:"-2%" }}>
                {
                  ( item.selected == true)?
                  (
                    <Grid item xs={1} className={classes.moreVertIcon}>
                      <CheckIcon style={{color:'black'}}/>
                    </Grid>
                  ):
                  (<div></div>)
                }
              </Grid> */}
          {/* </Grid>
          )}
        </Grid> */}


        </Grid>

        <div style={{ display: 'flex', marginTop: '4%', marginBottom: '-20%' }}>
          <div style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              onClick={handleFilterModalReset}
            >
              Reset
            </Button>
          </div>
          <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', marginLeft: '37%' }}>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              onClick={handleFilterModalClose}
            >
              Cancel
            </Button>
          </div>
          {/* <div style={{marginRigthe:'-1%'}}></div> */}
          <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', marginLeft: '2%' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submitApplyFilters}
              onClick={handelSubmitApplyFilter}
            >
              Apply Filters
            </Button>
          </div>
        </div>

      </div>
    </div>
  )
  const handleRowClickOfTable = async (item) => {
    // console.log("handleRowClickOfTable is clicked",item)
    await localStorage.setItem('selected_case_for_edit', JSON.stringify(item));
    await localStorage.setItem('selected_case_for_details', JSON.stringify(item));
    await localStorage.setItem('navigated_toshow_case_from', JSON.stringify("Cases"));
    // if( item.case_curr_state == "CONFIRMED" || item.case_curr_state == "PENDING" || 
    //   item.case_curr_state == "PAYMENT-PENDING" || item.case_curr_state == "COMPLETED" ){
    if (item.case_curr_state == "IN-PROGRESS" || item.case_curr_state == "PENDING" ||
      item.case_curr_state == "PAYMENT-PENDING" || item.case_curr_state == "COMPLETED") {
      {
        user_permissions && user_permissions.permissions.view_case_details &&
        await setRedirectToTaxManagementCaseDetails(true);
      }
    }
    else if (item.case_curr_state == "CREATED") {
      {
        user_permissions && user_permissions.permissions.view_case_details &&
        await setRedirectToTaxManagementCaseDetails(true);
      }
    }
    else if (item.case_curr_state == "INITIATED") {
      // await setRedirectToTrpEditCase(true);
      await setRedirectToClientEditCase(true);
    }
    else if (item.case_curr_state == "DRAFT") {
      await setRedirectToClientEditCase(true);
    }
    else if (item.case_curr_state == "CLOSED") {
      {
        user_permissions && user_permissions.permissions.view_case_details &&
        await setRedirectToTaxManagementCaseDetails(true);
      }
      // Swal.fire({
      //   // icon: 'error',
      //   text: "Case is closed",
      //   confirmButtonColor: 'primary',
      //   confirmButtonText: 'OK'
      // })
    }
    else {
      Swal.fire({
        // icon: 'error',
        text: "Case is not Confirmed yet",
        confirmButtonColor: 'primary',
        confirmButtonText: 'OK'
      })
    }
  }

  const handleCellClickOfClientName = async (item) => {
    console.log("handleCellClickOfClientName is clicked",item.client_id)
    let client_id_is = item.client_id
    // ClientDetails && ClientDetails.map(async (itemc, index) => {
      // console.log("in clientDetails map ", itemc.client_id, item.client_id)
      // if (item.client_id === itemc.client_id) {
      //   console.log("item is matched ", item, itemc)
        // if (itemc.client_state == "REGISTERED") {
          if (user_permissions && user_permissions.permissions.view_client_details == true) {
            // await localStorage.setItem('selected_Client_Showin_Ui', JSON.stringify(itemc));
            await localStorage.setItem('selected_Client_Showin_Ui', JSON.stringify(client_id_is));
            await localStorage.setItem('navigated_toshow_client_details_from', JSON.stringify("TaxManagemantCase"));
            await setRedirectToGetClientDetailsPage(true);
          }
          else {
            Swal.fire({
              // icon: 'error',
              text: "Sorry. You don't have permission to see client details",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        // }
        // else if (itemc.client_state == "INVITED" || itemc.client_state == "CREATED") {
        //   if (user_permissions && user_permissions.permissions.create_client == true) {
        //     await localStorage.setItem('selected_Client_To_Edit_Self_Details', JSON.stringify(itemc));
        //     await localStorage.setItem('navigated_to_edit_self_client_details', JSON.stringify("TaxManagemantCasesTableTrpAdmin"));
        //     await setRedirectToEditClientDetails(true);
        //   }
        //   else {
        //     Swal.fire({
        //       // icon: 'error',
        //       text: "Sorry. You don't have permission to see client details",
        //       confirmButtonColor: '#d33',
        //       confirmButtonText: 'OK'
        //     })
        //   }
        // }
      //   else {
      //     Swal.fire({
      //       // icon: 'error',
      //       text: "Client is not Registered yet",
      //       confirmButtonColor: '#d33',
      //       confirmButtonText: 'OK'
      //     })
      //   }
      // }
      // else{
      //   console.log("not =========")
      // }
    // })
  }

  const createdDateFunc = (date, item) => {
    // console.log("date in createdDateFunc is", date, item.name)

    // // { console.log("item.created_date in table map is", item.created_date) }
    // // { console.log("new Date(item.created_date) in table map is", new Date(item.created_date)) }
    // // { console.log("item.created_date.toLocaleString() in table map is", item.created_date.toLocaleString()) }
    // // { console.log("item.created_date.toString()  in table map is", item.created_date.toString()) }
    // {/* 14/04/2023 18:44   dd/mm/yyyy this is original */ }
    // { console.log("date is 6/29/2011 4:52:48 PM UTC' in table map is", new Date('6/29/2011 4:52:48 PM UTC')) }
    // { console.log("date is from our server 05/01/2023 04:54 ' in table map is", new Date('05/01/2023 04:54 ')) }
    // { console.log("date is from our server 05/01/2023 04:54 PM UTC' in table map is", new Date('05/01/2023 04:54 PM UTC')) }
    // { console.log("date is from our server 05/01/2023 04:54  UTC' in table map is", new Date('05/01/2023 04:54 UTC')) }

    // { console.log("date is from our server 05/01/2023 22:00  UTC' in table map is", new Date('05/01/2023 22:00 UTC')) }
    // {/* 04/14/2023 18:44 mm/dd/yyyy we need in this one */ }
    // // { console.log("moment(new Date('14/04/2023 18:44')).format('MM/DD/YYYY')", moment("14/04/2023").format('MM/DD/YYYY')) }
    // {/* {console.log("moment(new Date('14/04/2023 18:44')).format('MM/DD/YYYY')",moment("04/14/2023").format('DD/MM/YYYY'))} working  */ }

    let dateInString = JSON.stringify(date)
    // let dateInString = date.toString()
    // console.log("date in straing is ", dateInString)
    // console.log("moment(new Date('14/04/2023 18:44')).format('MM/DD/YYYY')", moment('14/04/2023').format('MM/DD/YYYY'))
    // console.log("dateInString to local", new Date("4/14/2013"))

    // let fomatedDate = moment(date).format('ddd, hA');
    // console.log("formated date is",fomatedDate)

    // var mydate = moment('15/11/2000 18:44', 'DD/MM/YYYY'); 
    // console.log("formated date is",moment(mydate).format("MM/DD/YYYY"))

    var mydateIs = moment(dateInString, 'DD/MM/YYYY hh:mm A');
    // console.log("formated date is ours",moment(mydateIs).format("MM/DD/YYYY hh:mm A"))
    var myDateWithFormatIs = moment(mydateIs).format("MM/DD/YYYY hh:mm A")
    // console.log("myDateWithFormatIs",myDateWithFormatIs)
    // console.log("date in format for  in table map is local", new Date(myDateWithFormatIs)) 

    // let myLocalDateIS = new Date(myDateWithFormatIs)
    // console.log("myLocalDateIS",myLocalDateIS)

    // let myNewStringDateis = myLocalDateIS.getFullYear() + "-"+(myLocalDateIS.getMonth()+1) +"-"+myLocalDateIS.getDate() + ' '+myLocalDateIS.toString().split(' ')[4];
    // console.log("myNewStringDateis",myNewStringDateis);

    // let myLocalDateISStringfy = JSON.stringify(myLocalDateIS)
    // console.log("myLocalDateISStringfy",myLocalDateISStringfy)

    // // let localDate = myLocalDateISStringfy.split("T").join("</br>");
    // let localDateSplit = myLocalDateISStringfy.split("T");
    // console.log("localDateSplit:",localDateSplit)
    // const localDate = localDateSplit[0].split('"');
    // // const localDate = localDateSplit[0];
    // console.log("localDate:",localDate)
    // const localTime = localDateSplit[1].split('.0');
    // // const localTime = localDateSplit[1];
    // console.log("localTime:",localTime)
    // // const localTime2 = localTime.substring(0, localTime.length() - 1);
    // // console.log("localTime2:",localTime2)
    // const localTime2 = localTime[0];
    // console.log("localTime2:",localTime2)

    var local_date = moment.utc(myDateWithFormatIs).local().format('MM/DD/YYYY hh:mm A');
    // console.log("local_date using moments is",local_date)

    var local_date_DayFirst = moment.utc(myDateWithFormatIs).local().format('DD/MMM/YYYY hh:mm A');
    // console.log("local_date day first",local_date_DayFirst)

    return (
      <div>
        <div>
          {/* {local_date} */}
          {local_date_DayFirst}
        </div>
        {/* <div>{myNewStringDateis}</div> */}
        {/* <div>{localDate}</div><br/>
        <div>{localTime2}</div> */}
      </div>
    )
  }

  const handleClearFilter = async () => {
    setflag(false);
    setMultiselectRefresh(false);
    setFilterIsApplied(false);
    setAssignUserFilter([]);
    setTagFilter([]);
    setServiceFilter([]);
    setClientsFilter([]);
    setRoleFilter([]);
    setClientGroupsFilter([]);

    setAssignUserFilterNames([]);
    setTagFilterNames([]);
    setServiceFilterNames([]);
    setClientsFilterNames([]);
    setRoleFilterNames([]);
    setClientGroupsFilterNames([]);

    // sort by
    await setSortByArray(
      SortByArray.map((f) => {
        f.selected = false;
        return f;
      })
    )
    // priority
    await setPriorityArray(
      PriorityArray.map((f) => {
        f.selected = false;
        return f;
      })
    )
    // tags
    await setallTagDetails(
      allTagDetails.map((f) => {
        f.selected = false;
        return f;
      })
    )
    // assigned people
    await setallUsersList(
      allUsersList.map((f) => {
        f.selected = false;
        return f;
      })
    )
    setMultiselectRefresh(true);

  }

  let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));

  if (RedirectToTaxManagementCaseDetails == true) {
    return <Redirect to={`/TaxManagementCaseDetails`} />
  }
  if (RedirectToTrpEditCase == true) {
    return <Redirect to={`/TrpEditCase`} />
  }
  if (RedirectToGetClientDetailsPage == true) {
    return <Redirect to={`/TaxManagementClientDetailsUI`} />
  }
  if (RedirectToEditClientDetails == true) {
    return <Redirect to={`/EditClientSelf`} />
  }
  if (RedirectToClientEditCase == true) {
    addqsnListRedux();
    addqsnListLevelTwoRedux();
    addqsnListLevelThreeRedux();
    newCaseActionResetCallRedux();


    return <Redirect to={`/EditCase`} />
  }
  else {
    return (
      // console.log("allUsersList",allUsersList),
      // console.log("totalNumberOfPagesFromApiin return", totalNumberOfPagesFromApi),
      // console.log("recentPageNumberFromApi return", recentPageNumberFromApi),
      console.log("casesList in return is", casesList, ShowTableFlag),
      console.log("page is", page),
      console.log("rowsPerPage is", rowsPerPage),
      // console.log("selectedUserToAssignId, selectedUserToAssignName",selectedUserToAssignId, selectedUserToAssignName),
      <div className={classes.rootrp}>
        {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
          <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
        </modal> : null}
        {/* <Grid style={{ marginTop: '-2%', marginLeft: '70%'}}> */}
        
        {
          ( ShowCasesForActiveClients === false )?
          (
            <Grid container justify="flex-end" >
              <Button
                variant="outlined"
                className={classes.customFilterLabel}
                startIcon={<FilterListIcon />}
                onClick={() => handleFilterModalOpen()}
              >
                {/* <FormattedMessage id="filtersLabel" /> */}
                Filter
              </Button>
              {
                (FilterIsApplied == true) ?
                  (
                    <Button
                      variant="outlined"
                      className={classes.customFilterLabel}
                      startIcon={<CloseIcon />}
                      onClick={() => handleClearFilter()}
                    >
                      Clear Filter
                    </Button>
                  ) :
                  (<Grid></Grid>)
              }
              {user_permissions && user_permissions.permissions.create_case &&
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonStyleNewCase}
                  startIcon={<AddIcon />}
                  href="/newcase"
                >
                  New Case
                </Button>
              }
            </Grid>
          ):
          (
            <Grid container justify="flex-end" >
              {user_permissions && user_permissions.permissions.create_case &&
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonStyleNewCase}
                  startIcon={<AddIcon />}
                  href="/newcase"
                >
                  New Case
                </Button>
              }
            </Grid>
          )
        }
       

        <div className={classes.wrapper} style={{ marginTop: '1%' }}>

          {/* <Breadcrumbs  aria-label="breadcrumb">
                <Link to="BusinessDashboard" >
                    BusinessDashboard
                </Link>
                <Typography color="textPrimary">casesList</Typography>
            </Breadcrumbs> */}
          {
            (ShowTableFlag === true) ?
              (
                <Grid>
                  
                  <TableContainer className={classes.tableWrapper} component={Paper}>
                  
                    <Table className={classes.table} aria-label="simple table">
                      <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        // rowCount={casesList && casesList.length}
                        rowCount={paginationDetails && paginationDetails.total_cases}
                      />
                      
                     
                      <TableBody>
                      {
                      ( ShowTableFlag === true )? 
                      (
                        <>
                        {stableSort(casesList && casesList, getComparator(order, orderBy))
                          // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((item, index) => {
                            {console.log("in ShowTableFlag true")}
                            console.log("row in table map", item)
                            // console.log(" row item.case_alert_message ", item.case_alert_message)
                            // else if( role_of_user == "trpadmin" ){
                            return (
                              <TableRow
                                key={item.case_id}
                                hover={true} classes={{ hover: classes.hover }}
                                // hover
                                tabIndex={-1}
                                // align="center"
                                style={{ height: 1 }}
                              >
                                {/* <TableCell width="10%" align="center">{item.case_id}</TableCell> */}
                                <TableCell onClick={() => handleRowClickOfTable(item)} width="10%" align="center">{item.name}</TableCell>
                                {/* <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center">{item.description}</TableCell> */}
                                <TableCell onClick={() => handleRowClickOfTable(item)} width="10%" align="center">{item.service}</TableCell>

                                {/* {console.log("item.created_date in table map is",item.created_date)}
                                  {console.log("new Date(item.created_date) in table map is",new Date(item.created_date))}
                                  {console.log("item.created_date.toLocaleString() in table map is",item.created_date.toLocaleString())}
                                  {console.log("item.created_date.toString()  in table map is",item.created_date.toString() )} */}
                                {/* 14/04/2023 18:44   dd/mm/yyyy this is original */}
                                {/* {console.log("date is 6/29/2011 4:52:48 PM UTC' in table map is",new Date('6/29/2011 4:52:48 PM UTC'))}
                                  {console.log("date is from our server 14/04/2023 18:44 ' in table map is",new Date('04/14/2023 18:44 '))} */}
                                {/* 04/14/2023 18:44 mm/dd/yyyy we need in this one */}
                                {/* {console.log("moment(new Date('14/04/2023 18:44')).format('MM/DD/YYYY')",moment("14/04/2023").format('MM/DD/YYYY'))} */}
                                {/* {console.log("moment(new Date('14/04/2023 18:44')).format('MM/DD/YYYY')",moment("04/14/2023").format('DD/MM/YYYY'))} working  */}
                                {/* {console.log("moment(new Date('14/04/2023 18:44')).format('MM/DD/YYYY')",moment("1994/07/01").format('DD/MM/YYYY'))}  working */}

                                {/* <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center">{item.created_date}</TableCell> */}
                                <TableCell onClick={() => handleRowClickOfTable(item)} width="10%" align="center">{createdDateFunc(item.created_date, item)}</TableCell>

                                {/* <TableCell width="10%" align="center">{item.process}</TableCell> 
                                  <TableCell width="10%" align="center">{item.service}</TableCell>  */}
                                {/* <TableCell width="10%" align="center">{item.description}</TableCell>  */}
                                <TableCell onClick={() => handleCellClickOfClientName(item)} width="10%" align="center">
                                  <Tooltip
                                    title="View Client Details"
                                    placement="center"
                                  >
                                    <text style={{ color: "blue" }}>{item.client_name}</text>
                                  </Tooltip>
                                </TableCell>
                                <TableCell onClick={() => handleRowClickOfTable(item)} width="10%" align="center">{item.client_pan}</TableCell>
                                <TableCell onClick={() => handleCellClickOfClientName(item)} width="10%" align="center">
                                  <Tooltip
                                    title="View Client Details"
                                    placement="center"
                                  >
                                    <Grid>
                                      <text style={{ color: "blue" }}>
                                        {(item.for_assesse_name === " " ? item.client_name : item.for_assesse_name)}
                                      </text><br/>
                                      <text style={{ color: "blue" }}>
                                        &nbsp;({(item.for_assesse_name === " " ? item.client_pan : item.for_assesse_pan)})
                                      </text>
                                    </Grid>
                                  </Tooltip>
                                </TableCell>
                                {/* <TableCell width="10%" align="center">{item.for_assesse_pan}</TableCell> */}
                                {/* <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center">{item.owner ? item.owner.owner : null}</TableCell> */}

                                <TableCell onClick={() => handleRowClickOfTable(item)} width="10%" align="center">
                                  {/* {item.case_curr_state == "CONFIRMED" ? "IN PROGRESS" : item.case_curr_state} */}
                                  {
                                    (item.case_curr_state == "CONFIRMED") ?
                                      (
                                        "IN-PROGRESS"
                                      ) :
                                      (
                                        // item.case_curr_state
                                        (item.case_curr_state == "PENDING" || item.case_curr_state == "PAYMENT-PENDING"
                                          || item.case_curr_state == "DRAFT") ?
                                          (
                                            <Grid>

                                              {
                                                (item.case_alert_message) ?
                                                  (
                                                    <Tooltip
                                                      title={item.case_alert_message !== null ? item.case_alert_message.message : null}
                                                      placement="right-start"
                                                    >
                                                      <Grid>{item.case_curr_state}<br /></Grid>
                                                    </Tooltip>
                                                  ) :
                                                  (
                                                    <Grid>{item.case_curr_state}<br /></Grid>
                                                  )
                                              }

                                              {/* working */}
                                              {/* <Tooltip
                                              title={ item.case_alert_message !== null ? item.case_alert_message.message : null}
                                              placement="right-start"
                                             >
                                              <Grid>{item.case_curr_state}<br/></Grid>
                                            </Tooltip> */}
                                              {/* working */}

                                              {/* {
                                              ( item.case_alert_message !== null )?
                                              (
                                                <Grid>
                                                  <Tooltip
                                                    title="Role BasedA ccess"
                                                    placement="right-start"
                                                  >
                                                    <Grid>{item.case_alert_message.message}</Grid>
                                                  </Tooltip>
                                                </Grid>
                                              ):
                                              (
                                                <Grid></Grid>
                                              )
                                            } */}

                                            </Grid>
                                          ) :
                                          (
                                            item.case_curr_state
                                          )
                                      )
                                  }
                                </TableCell>
                                <TableCell onClick={() => handleRowClickOfTable(item)} width="10%" align="center">{item.priority}</TableCell>
                                <TableCell onClick={() => handleRowClickOfTable(item)} width="10%" align="center">{item.current_step}</TableCell>
                                <TableCell onClick={() => handleRowClickOfTable(item)} width="10%" align="center">{item.current_state}</TableCell>
                                {/* {( item.case_curr_state == "DRAFT" || item.case_curr_state == "INITIATED" && ) */}
                                {
                                  (item.case_curr_state == "INITIATED")
                                    ? (
                                      <TableCell width="5%" align="center" >
                                        {/* <Button
                                          size="small"
                                          variant="contained"
                                          color="primary"
                                          className={classes.button}
                                          href="/TrpEditCase"
                                          onClick={() => handleClickEditCaseAdmin(item)}
                                        // >Edit Case Admin</Button>
                                        >Edit Case</Button> */}
                                        <IconButton
                                          aria-label="more"
                                          aria-controls="long-menu"
                                          aria-haspopup="true"
                                          onClick={(event) => handleClickEditCaseAdmin(event, item)}
                                        // onClick={handleClose("Settings0",item)}
                                        >
                                          <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                          id="long-menu"
                                          anchorEl={anchorInitiated}
                                          keepMounted
                                          open={openInitiated}
                                          onClose={() => handleCloseEditCase("Settings0", item)}
                                          PaperProps={{
                                            style: {
                                              maxHeight: ITEM_HEIGHT * 4.5,
                                              width: '20ch',
                                            },
                                          }}
                                        >
                                          <MenuItem onClick={() => handleCloseEditCase("Edit", item)}>
                                            {/* <a href="/TrpEditCase" style={{color:'black',  */}
                                            <a href="/EditCase" style={{
                                              color: 'black',
                                              textDecoration: 'none'
                                            }}>Edit Case</a>
                                          </MenuItem>
                                          {/* <MenuItem onClick={ () => handleCloseEditCase("Settings2",item)}>
                                            Settings2
                                          </MenuItem> */}

                                        </Menu>
                                      </TableCell>
                                    )
                                    : (
                                      (item.case_curr_state == "CREATED") ?
                                        (
                                          <TableCell width="5%" align="center" >
                                            {/* <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            href="/TaxManagementCaseDetails"
                                            onClick={() => handleClickShowCaseDetails(item)}
                                          >Show Case</Button> */}

                                            <IconButton
                                              aria-label="more"
                                              aria-controls="long-menu"
                                              aria-haspopup="true"
                                              onClick={(event) => handleClickShowCaseDetails(event, item)}
                                            // onClick={handleClose("Settings0",item)}
                                            >
                                              <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                              id="long-menu"
                                              anchorEl={anchorEl}
                                              keepMounted
                                              open={open}
                                              onClose={() => handleCloseShowCase("Settings0")}
                                              PaperProps={{
                                                style: {
                                                  maxHeight: ITEM_HEIGHT * 4.5,
                                                  width: '20ch',
                                                },
                                              }}
                                            >
                                              {user_permissions && user_permissions.permissions.view_case_details &&
                                                <MenuItem onClick={() => handleCloseShowCase("Edit")}>
                                                  <a href="/TaxManagementCaseDetails" style={{
                                                    color: 'black',
                                                    textDecoration: 'none'
                                                  }}>
                                                    {/* Show Case */}
                                                    Case Details
                                                  </a>
                                                </MenuItem>
                                              }

                                              {user_permissions && user_permissions.permissions.switch_process &&
                                                <MenuItem onClick={() => handleCloseShowCase("SwitchProcess")}>
                                                  <a
                                                    // onClick={() => handleSwitchProcessModalOpen()} 
                                                    style={{
                                                      color: 'black',
                                                      textDecoration: 'none'
                                                    }}>
                                                    Switch Process
                                                  </a>
                                                </MenuItem>
                                              }

                                              {user_permissions && user_permissions.permissions.confirm_case &&
                                                <MenuItem onClick={() => handleCloseShowCase("ConfirmCase")}>
                                                  Confirm Case
                                                </MenuItem>
                                              }

                                              <MenuItem onClick={() => handleCloseShowCase("RejectCase")}>
                                                {/* Reject Case */}
                                                Revert to draft
                                              </MenuItem>
                                              {user_permissions && user_permissions.permissions.assign_user_to_case &&
                                                <MenuItem onClick={() => handleCloseShowCase("AssignUser")}>
                                                  {/* <a onClick={() => handleAssignUserModalOpenBefore()} style={{color:'black', 
                                                  textDecoration: 'none'}}> */}
                                                  <a style={{ color: 'black', textDecoration: 'none' }}>
                                                    {/* Assign User */}
                                                    Assign Case Owner
                                                  </a>
                                                </MenuItem>
                                              }
                                            </Menu>

                                          </TableCell>

                                        ) :
                                        (
                                          // <TableCell width="5%" align="center" ></TableCell>
                                          (item.case_curr_state == "IN-PROGRESS"
                                            || item.case_curr_state == "PAYMENT-PENDING") ?
                                            (
                                              <TableCell width="5%" align="center" >
                                                <IconButton
                                                  aria-label="more"
                                                  aria-controls="long-menu"
                                                  aria-haspopup="true"
                                                  onClick={(event) => handleClickShowCaseDetailsCONFIRMED(event, item)}
                                                // onClick={handleClose("Settings0",item)}
                                                >
                                                  <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                  id="long-menu"
                                                  anchorEl={anchorElTwo}
                                                  keepMounted
                                                  open={openConfirmed}
                                                  onClose={() => handleCloseShowCaseCONFIRMED("Settings0")}
                                                  PaperProps={{
                                                    style: {
                                                      maxHeight: ITEM_HEIGHT * 4.5,
                                                      width: '20ch',
                                                    },
                                                  }}
                                                >
                                                  {user_permissions && user_permissions.permissions.view_case_details &&
                                                    <MenuItem onClick={() => handleCloseShowCaseCONFIRMED("Edit")}>
                                                      <a href="/TaxManagementCaseDetails" style={{
                                                        color: 'black',
                                                        textDecoration: 'none'
                                                      }}>
                                                        {/* Show Case */}
                                                        Case Details
                                                      </a>
                                                    </MenuItem>
                                                  }

                                                  {user_permissions && user_permissions.permissions.switch_step &&
                                                    <MenuItem onClick={() => handleCloseShowCaseCONFIRMED("SwitchStep")}>
                                                      {/* Switch Step */}
                                                      <a
                                                        // onClick={() => handleSwitchStepModalOpen()} 
                                                        style={{ color: 'black', textDecoration: 'none' }}
                                                      >
                                                        Switch Step
                                                      </a>
                                                    </MenuItem>
                                                  }

                                                  {user_permissions && user_permissions.permissions.assign_user_to_case &&
                                                    <MenuItem onClick={() => handleCloseShowCaseCONFIRMED("AssignUser")}>
                                                      {/* <a onClick={() => handleAssignUserModalOpen()} style={{color:'black', 
                                                  textDecoration: 'none'}}> */}
                                                      <a style={{ color: 'black', textDecoration: 'none' }}>
                                                        {/* Assign User */}
                                                        Assign Case Owner
                                                      </a>
                                                    </MenuItem>
                                                  }

                                                  <MenuItem onClick={() => handleCloseShowCaseCONFIRMED("PendingInfo")}>
                                                    {/* <a onClick={() => handleAssignUserModalOpen()} style={{color:'black', 
                                              textDecoration: 'none'}}> */}
                                                    <a style={{ color: 'black', textDecoration: 'none' }}>
                                                      Pending Info
                                                    </a>
                                                  </MenuItem>
                                                  {/* {user_permissions && user_permissions.permissions.close_case &&
                                              <MenuItem onClick={ () => handleCloseShowCaseCONFIRMED("CloseCase")}>
                                                <a style={{color:'black', textDecoration: 'none'}}>
                                                  Close Case
                                                </a> 
                                              </MenuItem>
                                            } */}

                                                </Menu>

                                              </TableCell>
                                            ) :
                                            // (<TableCell width="5%" align="center" ></TableCell>)
                                            (
                                              (item.case_curr_state == "DRAFT") ?
                                                (
                                                  <TableCell width="5%" align="center" >
                                                    <IconButton
                                                      aria-label="more"
                                                      aria-controls="long-menu"
                                                      aria-haspopup="true"
                                                      onClick={(event) => handleClickEditCaseAdminClient(event, item)}
                                                    >
                                                      <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                      id="long-menu"
                                                      anchorEl={anchorInitiated2}
                                                      keepMounted
                                                      open={openInitiated2}
                                                      onClose={() => handleCloseEditCaseClient("Settings0", item)}
                                                      PaperProps={{
                                                        style: {
                                                          maxHeight: ITEM_HEIGHT * 4.5,
                                                          width: '20ch',
                                                        },
                                                      }}
                                                    >
                                                      <MenuItem
                                                        onClick={() => handleCloseEditCaseClient("Edit", item)}
                                                      >
                                                        <a href="/EditCase" style={{
                                                          color: 'black',
                                                          textDecoration: 'none'
                                                        }}>Edit Case</a>
                                                      </MenuItem>
                                                    </Menu>
                                                  </TableCell>
                                                ) :
                                                (
                                                  // <TableCell width="5%" align="center" ></TableCell>
                                                  (item.case_curr_state == "PENDING") ?
                                                    (
                                                      <TableCell width="5%" align="center" >
                                                        <IconButton
                                                          aria-label="more"
                                                          aria-controls="long-menu"
                                                          aria-haspopup="true"
                                                          onClick={(event) => handleClickShowCaseDetailspending(event, item)}
                                                        // onClick={handleClose("Settings0",item)}
                                                        >
                                                          <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu
                                                          id="long-menu"
                                                          anchorEl={anchorElThree}
                                                          keepMounted
                                                          open={openPending}
                                                          onClose={() => handleCloseShowCasePending("Settings0")}
                                                          PaperProps={{
                                                            style: {
                                                              maxHeight: ITEM_HEIGHT * 4.5,
                                                              width: '22ch',
                                                            },
                                                          }}
                                                        >
                                                          {user_permissions && user_permissions.permissions.view_case_details &&
                                                            <MenuItem onClick={() => handleCloseShowCasePending("Edit")}>
                                                              <a href="/TaxManagementCaseDetails" style={{
                                                                color: 'black',
                                                                textDecoration: 'none'
                                                              }}>
                                                                {/* Show Case */}
                                                                Case Details
                                                              </a>
                                                            </MenuItem>
                                                          }

                                                          {user_permissions && user_permissions.permissions.switch_step &&
                                                            <MenuItem onClick={() => handleCloseShowCasePending("SwitchStep")}>
                                                              {/* Switch Step */}
                                                              <a
                                                                // onClick={() => handleSwitchStepModalOpen()} 
                                                                style={{ color: 'black', textDecoration: 'none' }}
                                                              >
                                                                Switch Step
                                                              </a>
                                                            </MenuItem>
                                                          }

                                                          {user_permissions && user_permissions.permissions.assign_user_to_case &&
                                                            <MenuItem onClick={() => handleCloseShowCasePending("AssignUser")}>
                                                              {/* <a onClick={() => handleAssignUserModalOpen()} style={{color:'black', 
                                                        textDecoration: 'none'}}> */}
                                                              <a style={{ color: 'black', textDecoration: 'none' }}>
                                                                {/* Assign User */}
                                                                Assign Case Owner
                                                              </a>
                                                            </MenuItem>
                                                          }

                                                          <MenuItem onClick={() => handleCloseShowCasePending("PendingInfoAway")}>
                                                            {/* <a onClick={() => handleAssignUserModalOpen()} style={{color:'black', 
                                                    textDecoration: 'none'}}> */}
                                                            <a style={{ color: 'black', textDecoration: 'none' }}>
                                                              Away From Pending
                                                            </a>
                                                          </MenuItem>

                                                          {/* {user_permissions && user_permissions.permissions.close_case &&
                                                    <MenuItem onClick={ () => handleCloseShowCasePending("CloseCase")}>
                                                      <a style={{color:'black', textDecoration: 'none'}}>
                                                        Close Case
                                                      </a> 
                                                    </MenuItem>
                                                  } */}

                                                        </Menu>

                                                      </TableCell>
                                                    ) :
                                                    (
                                                      // <TableCell width="5%" align="center" ></TableCell>
                                                      (item.case_curr_state == "COMPLETED") ?
                                                        (
                                                          <TableCell width="5%" align="center" >
                                                            {
                                                              (user_permissions && user_permissions.permissions.close_case === true) ?
                                                                (
                                                                  <div>
                                                                    <IconButton
                                                                      aria-label="more"
                                                                      aria-controls="long-menu"
                                                                      aria-haspopup="true"
                                                                      onClick={(event) => handleClickShowCaseDetailsCompleted(event, item)}
                                                                    // onClick={handleClose("Settings0",item)}
                                                                    >
                                                                      <MoreVertIcon />
                                                                    </IconButton>
                                                                    <Menu
                                                                      id="long-menu"
                                                                      anchorEl={anchorElFour}
                                                                      keepMounted
                                                                      open={openCompleted}
                                                                      onClose={() => handleCloseCaseCompleted("Settings0")}
                                                                      PaperProps={{
                                                                        style: {
                                                                          maxHeight: ITEM_HEIGHT * 4.5,
                                                                          width: '22ch',
                                                                        },
                                                                      }}
                                                                    >
                                                                      <MenuItem onClick={() => handleCloseCaseCompleted("CloseCase")}>
                                                                        <a style={{ color: 'black', textDecoration: 'none' }}>
                                                                          Close Case
                                                                        </a>
                                                                      </MenuItem>

                                                                    </Menu>
                                                                  </div>
                                                                ) :
                                                                (<div></div>)
                                                            }

                                                          </TableCell>
                                                        ) :
                                                        (<TableCell width="5%" align="center" ></TableCell>)

                                                    )
                                                )

                                            )
                                        )
                                    )
                                }
                                {/* <TableCell width="5%" align="center">                                */}
                                {/* <Button
                                      ref={anchorRef}
                                      className={classes.setPriorityButton}
                                      // aria-controls={OpenChangeStatus ? "menu-list-grow" : undefined}
                                      aria-haspopup="true"
                                      id="setPriorityLabel"
                                      onClick={() => handleToggle()}
                                    >
                                      <MoreVertIcon/>
                                    </Button> */}
                                {/* <IconButton
                                      aria-label="more"
                                      aria-controls="long-menu"
                                      aria-haspopup="true"
                                      onClick={(event) => handleClick(event,item)}
                                      // onClick={handleClose("Settings0",item)}
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                      id="long-menu"
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={open}
                                      onClose={ () => handleClose("Settings0",item)}
                                      PaperProps={{
                                        style: {
                                          maxHeight: ITEM_HEIGHT * 4.5,
                                          width: '20ch',
                                        },
                                      }}
                                    >
                                      <MenuItem onClick={ () => handleClose("Settings1",item)}>
                                        Settings
                                      </MenuItem>
                                      <MenuItem onClick={ () => handleClose("Settings2",item)}>
                                        Settings2
                                      </MenuItem>

                                    </Menu> */}

                                {/* <Popper
                                      open={OpenChangeStatus}
                                      anchorEl={anchorRef.current}
                                      role={undefined}
                                      transition
                                      disablePortal
                                    >
                                      {({ TransitionProps, placement }) => (
                                        <Grow
                                          {...TransitionProps}
                                          style={{
                                            transformOrigin:
                                              placement === "bottom" ? "center top" : "center bottom",
                                          }}
                                        >
                                        <Paper>
                                          <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList
                                              autoFocusItem={OpenChangeStatus}
                                              id="menu-list-grow"
                                              onKeyDown={handleListKeyDown}
                                            >
                                              <MenuItem onClick={() => handleCloseAfterClick("Settings")} >
                                                Settings 
                                              </MenuItem>
                                              <MenuItem onClick={() => handleCloseAfterClick("Settings")} >
                                                Settings 
                                              </MenuItem>
                                            </MenuList>
                                          </ClickAwayListener>
                                        </Paper>
                                      </Grow>
                                      )}
                                    </Popper> */}

                                {/* <div>
                                      <Popover placement="bottom" trigger="legacy" target="setPriorityLabel"
                                        isOpen={OpenChangeStatus} 
                                        toggle={handleToggle}
                                        style={{ backgroundColor: "white", color: "white", width: "150%", marginLeft: "-40%",
                                                padding: 10 }}
                                      >
                                        <PopoverBody>
                                          <div style={{color:'black'}}>Settings</div>                 
                                          <hr/>
                                          <div style={{color:'black'}}>Seiings</div> 
                                          
                                        </PopoverBody>
                                      </Popover>
                                    </div> */}

                                {/* </TableCell>                                 */}
                              </TableRow>
                            );
                            // }
                          })}
                        </>
                        ):
                        (<></>)
                      }
  
                      </TableBody>
                       
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: casesList && casesList.length }]}
                            rowsPerPageOptions={[100, 50, 25, 10, { label: 'All', value: paginationDetails && paginationDetails.total_cases }]}
                            colSpan={10}
                            // count={casesList && casesList.length}
                            count={paginationDetails && paginationDetails.total_cases}
                            // count={-1}
                            // count={totalNumberOfPagesFromApi}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: { 'aria-label': 'rows per page' },
                              native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Grid>
              ) :
              (
                <></>
              )
          }


        </div>
        {/* Assign User */}
        <Modal
          open={openAssignUser}
          onClose={handleAssignUserModalClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyAssignUser}
        </Modal>

        {/* switch process */}
        <Modal
          open={openSwitchProcess}
          onClose={handleSwitchProcessModalClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodySwitchProcess}
        </Modal>

        {/* switch step modal */}
        <Modal
          open={openSwitchStep}
          onClose={handleSwitchStepModalClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodySwitchStep}
        </Modal>

        {/* Pending Info */}
        <Modal
          open={OpenPendingInfo}
          onClose={handlePendingInfoModalClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyPendingInfo}
        </Modal>

        {/* close case modal */}
        <Modal
          open={OpenCloseCase}
          onClose={handleCloseCaseModalClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyCloseCase}
        </Modal>

        <Modal
          open={OpenFilter}
          onClose={handleFilterModalClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyFilter}
        </Modal>
        {/* Reject case */}
        <Modal
          open={OpenRejectCase}
          onClose={handleRejectCaseModalClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyRejectCase}
        </Modal>

        {/* <FilterViewCases handleDrawerClose={handleDrawerClose} />                    */}
      </div>
    )
  }
}